import { z } from "zod";

const { VERCEL_ENV, VERCEL_PROJECT_PRODUCTION_URL, VERCEL_URL } = z
  .object({
    VERCEL_ENV: z.union([
      z.literal("development"),
      z.literal("preview"),
      z.literal("production"),
    ]),
    VERCEL_PROJECT_PRODUCTION_URL: z.string(),
    VERCEL_URL: z.string(),
  })
  .parse({
    // HACK I specifically don't want these empty strings, but the preview environment doesn't know it's in nextjs, so it doesn't set the NEXT_PUBLIC_ variables
    VERCEL_ENV:
      process.env.NEXT_PUBLIC_VERCEL_ENV ?? process.env.VERCEL_ENV ?? "preview",
    VERCEL_PROJECT_PRODUCTION_URL:
      process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL ??
      process.env.VERCEL_PROJECT_PRODUCTION_URL ??
      "",
    VERCEL_URL:
      process.env.NEXT_PUBLIC_VERCEL_URL ?? process.env.VERCEL_URL ?? "",
  });

export { VERCEL_ENV, VERCEL_PROJECT_PRODUCTION_URL };

export const baseUrlWithoutScheme =
  process.env.NODE_ENV === "development"
    ? "localhost:3000"
    : VERCEL_ENV === "production"
    ? VERCEL_PROJECT_PRODUCTION_URL
    : VERCEL_URL;

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : `https://${
        VERCEL_ENV === "production" ? VERCEL_PROJECT_PRODUCTION_URL : VERCEL_URL
      }`;
