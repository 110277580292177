import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { motion } from "framer-motion";
import { memo, useEffect, useState } from "react";
import type { ComponentProps, ReactNode } from "react";

import { Button } from "../Button";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: { spacing },
} = tailwindConfig;

const ModalPure = ({
  children,
  onAnimationComplete,
  onClose,
  open,
  ...props
}: Omit<ComponentProps<typeof motion.div>, "children"> & {
  children: ReactNode | ((openComplete: boolean) => ReactNode);
  onClose: () => void;
  open: boolean;
}) => {
  useEffect(() => {
    if (!open) {
      return () => {};
    }

    const onKeyDown = ({
      altKey,
      code,
      ctrlKey,
      metaKey,
      shiftKey,
    }: KeyboardEvent) => {
      if (altKey || ctrlKey || metaKey || shiftKey || code !== "Escape") {
        return;
      }

      onClose();
    };

    globalThis.addEventListener("keydown", onKeyDown);

    return () => globalThis.removeEventListener("keydown", onKeyDown);
  }, [onClose, open]);

  const [openComplete, setOpenComplete] = useState(open);

  return (
    <motion.div
      {...props}
      className={`fixed z-50 flex h-screen w-screen flex-col items-center justify-center ${
        open && openComplete ? "pointer-events-auto" : "pointer-events-none"
      }`}
      initial={openComplete ? "open" : "closed"}
      animate={open ? "open" : "closed"}
      onAnimationComplete={(definition) => {
        onAnimationComplete?.(definition);
        setOpenComplete(definition === "open");
      }}
    >
      <motion.div
        className="fixed h-full w-full"
        onClick={onClose}
        variants={{
          closed: { backgroundColor: "#00000000" },
          open: { backgroundColor: "#00000066" },
        }}
      />
      <motion.div
        className="absolute flex h-screen w-screen flex-col items-center bg-white p-5 pt-14 text-slate-950 drop-shadow-2xl sm:mt-16 sm:h-auto sm:max-h-[90vh] sm:min-h-[50vh] sm:max-w-xl sm:overflow-y-auto sm:rounded-xl dark:bg-zinc-950 dark:text-slate-50"
        variants={{
          closed: { top: "200%", opacity: 0 },
          open: { top: "0", opacity: 1 },
        }}
      >
        <div className="absolute left-2 top-2">
          <Button title="Close" onClick={onClose}>
            <CloseRoundedIcon
              style={{
                height: spacing.control,
                width: spacing.control,
              }}
            />
          </Button>
        </div>
        {typeof children === "function" ? children(openComplete) : children}
      </motion.div>
    </motion.div>
  );
};

export const Modal = memo(ModalPure);
