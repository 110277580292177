import WhatshotRounded from "@mui/icons-material/WhatshotRounded";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";

import { MotionDiv } from "../Motion";
import { Sprinkle } from "../Sprinkle";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: {
    spacing,
    colors: { fire },
  },
} = tailwindConfig;

// If each of the fills don't have a slightly different scale, then they won't re-animate when overriding one another
export const fireScale = 1.100002;

export const Fire = ({
  className,
  immediate = false,
  ...props
}: ComponentProps<typeof MotionDiv> & {
  immediate?: boolean;
}) => (
  <motion.div
    className={`pointer-events-none relative size-blockContent ${className}`}
  >
    {!immediate && <Sprinkle className="absolute top-0" variantName="water" />}
    <MotionDiv
      {...props}
      className="absolute top-0 size-blockContent"
      variants={{
        // TODO We need some fire animations
        inactive: { opacity: 0.5, color: fire.outer.inactive, scale: 1 },
        disactive: { opacity: 1, color: fire.outer.disactive, scale: 1 },
        unfilled: { opacity: 1, color: fire.outer.unfilled, scale: 1 },
        sun: { opacity: 1, color: fire.outer.sun, scale: 1 },
        fire: { opacity: 1, color: fire.outer.fire, scale: 1 },
        water: {
          opacity: 1,
          color: fire.outer.water,
          scale: [1, 2, 1.8, 1],
          transition: immediate
            ? { delay: 0, duration: 0 }
            : { delay: 0.15, duration: 0.4, bounce: true },
        },
      }}
    >
      <motion.div
        className="absolute size-blockContent translate-x-1 translate-y-3 scale-50 rounded-full"
        variants={{
          // TODO We need some fire/water animations
          inactive: { opacity: 0, backgroundColor: fire.inner.inactive },
          disactive: { opacity: 1, backgroundColor: fire.inner.disactive },
          unfilled: { opacity: 1, backgroundColor: fire.inner.unfilled },
          sun: { opacity: 1, backgroundColor: fire.inner.sun },
          fire: { opacity: 1, backgroundColor: fire.inner.fire },
          water: { opacity: 1, backgroundColor: fire.inner.water },
        }}
      />
      <WhatshotRounded
        className="relative"
        style={{
          height: spacing.blockContent,
          width: spacing.blockContent,
        }}
      />
    </MotionDiv>
  </motion.div>
);
