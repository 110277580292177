import type { Howl, HowlOptions } from "howler";
import { mapValues, noop } from "lodash/fp";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";

import type { Tuple } from "@sunblocks/utils";

type HowlOptionsPlus = Pick<HowlOptions, "html5" | "loop" | "src" | "volume"> &
  Required<Pick<HowlOptions, "preload">> & {
    interrupt?: boolean;
  };

export const howlerOptions = {
  blockDrop: { src: "/block-drop.wav" },
  blockDropImmobile: { src: "/block-drop-immobile.wav" },
  blockLift: { src: "/block-lift.wav", interrupt: false },
  blockSun: { src: "/block-sun-%s.wav" },
  cellMove: { src: "/cell-move.wav", interrupt: false },
  cellSun: { src: "/cell-sun-%s.wav" },
  levelAvailable: {
    src: "/level-available.wav",
    interrupt: false,
    volume: 0.5,
  },
  levelBackground: { html5: true, interrupt: false, loop: true, volume: 0 },
  levelDone: { src: "/level-done.wav", interrupt: false },
  levelDoneFull: { src: "/level-done-full.wav", interrupt: false },
  nActive: { src: "/n-active.wav" },
  nDisactive: { src: "/n-disactive.wav" },
  nodeFire: { src: "/node-fire.wav", interrupt: false },
  nodeWater: { src: "/node-water.wav", interrupt: false, volume: 0.5 },
  nPlusOne: { src: "/n-plus-one.wav" },
  sinkSatisfied: { src: "/sink-satisfied.wav" },
  sinkSatisfiedEnd: { src: "/sink-satisfied-end.wav" },
  sinkSatisfiedEndFull: { src: "/sink-satisfied-end-full.wav" },
  sinkSatisfiedWater: { src: "/sink-satisfied-water.wav", volume: 0.5 },
  weakActive: { src: "/weak-active.wav", volume: 0.5 },
  weakDisactive: { src: "/weak-disactive.wav" },
};

const defaultSounds = {} as {
  [src: string]: {
    props: HowlOptionsPlus;
    sound?: Howl;
  };
};

const defaultHowlerSounds = [
  defaultSounds,
  noop as Dispatch<SetStateAction<typeof defaultSounds>>,
] satisfies Tuple;

const HowlerSoundsContext = createContext(defaultHowlerSounds);

export const useHowler = ({
  html5,
  loop,
  preload,
  src,
  volume = 1,
  interrupt = true,
}: HowlOptionsPlus) => {
  const [sounds, setSounds] = useContext(HowlerSoundsContext);

  const soundKey = useMemo(
    () => (Array.isArray(src) ? src.join(",") : src),
    [src]
  );

  useEffect(
    () =>
      setSounds((sounds) =>
        soundKey in sounds || !preload
          ? sounds
          : {
              ...sounds,
              [soundKey]: { props: { html5, loop, preload, src, volume } },
            }
      ),
    [html5, loop, preload, setSounds, soundKey, src, volume]
  );

  const sound = sounds[soundKey]?.sound;

  const fade = useCallback(
    (...args: Parameters<Howl["fade"]>) => sound?.fade(...args),
    [sound]
  );
  const stop = useCallback(() => sound?.stop(), [sound]);

  return [
    useCallback(() => {
      if (!preload || !sound) {
        return;
      }

      if (interrupt) {
        sound.stop();
      }

      sound.play();
    }, [interrupt, preload, sound]),
    useMemo(() => ({ fade, sound, stop }), [fade, sound, stop]),
  ] satisfies Tuple;
};

export const useHowler16 = ({
  src,
  ...options
}: HowlOptionsPlus & { src: string }) =>
  [
    [
      useHowler({ src: src.replace("%s", "1"), ...options })[0],
      useHowler({ src: src.replace("%s", "2"), ...options })[0],
      useHowler({ src: src.replace("%s", "3"), ...options })[0],
      useHowler({ src: src.replace("%s", "4"), ...options })[0],
      useHowler({ src: src.replace("%s", "5"), ...options })[0],
      useHowler({ src: src.replace("%s", "6"), ...options })[0],
      useHowler({ src: src.replace("%s", "7"), ...options })[0],
      useHowler({ src: src.replace("%s", "8"), ...options })[0],
      useHowler({ src: src.replace("%s", "9"), ...options })[0],
      useHowler({ src: src.replace("%s", "10"), ...options })[0],
      useHowler({ src: src.replace("%s", "11"), ...options })[0],
      useHowler({ src: src.replace("%s", "12"), ...options })[0],
      useHowler({ src: src.replace("%s", "13"), ...options })[0],
      useHowler({ src: src.replace("%s", "14"), ...options })[0],
      useHowler({ src: src.replace("%s", "15"), ...options })[0],
      useHowler({ src: src.replace("%s", "16"), ...options })[0],
    ] satisfies Tuple,
  ] satisfies Tuple;

export const HowlerSounds = ({
  children,
  prefix = "",
  suffix = "",
}: {
  children: ReactNode;
  prefix?: string;
  suffix?: string;
}) => {
  const [HowlClass, setHowlClass] = useState<typeof Howl | null>(null);

  useEffect(() => {
    if (HowlClass) {
      return;
    }

    (async () => {
      const imported = await import("howler");

      setHowlClass(
        (HowlClass) => HowlClass ?? imported.Howl ?? imported.default.Howl
      );
    })();
  }, [HowlClass]);

  const [sounds, setSounds] = useState(defaultSounds);

  useEffect(() => {
    if (
      !HowlClass ||
      Object.values(sounds).every(({ sound }) => Boolean(sound))
    ) {
      return;
    }

    setSounds(
      mapValues((value) =>
        value.sound
          ? value
          : {
              ...value,
              sound:
                value.sound ??
                new HowlClass({
                  ...value.props,
                  src: Array.isArray(value.props.src)
                    ? value.props.src.map((src) => `${prefix}${src}?${suffix}`)
                    : `${prefix}${value.props.src}?${suffix}`,
                }),
            }
      )
    );
  }, [HowlClass, prefix, sounds, suffix]);

  return (
    <HowlerSoundsContext.Provider
      value={useMemo(() => [sounds, setSounds], [setSounds, sounds])}
    >
      {children}
    </HowlerSoundsContext.Provider>
  );
};
