import { TRPCClientError } from "@trpc/client";
import { addDays } from "date-fns/fp";
import { usePostHog } from "posthog-js/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import type { ReactNode } from "react";
import { z } from "zod";

import type { RouterOutputs } from "@sunblocks/trpc";

import { SuperJSON } from "src/superjson";
import { trpc } from "src/trpc/client";

type LoginProvider = "google";

const AuthContext = createContext({
  login: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- EXPECTED
    provider: LoginProvider
  ) => {},
  logout: () => {},
  session: undefined as RouterOutputs["auth"]["session"] | null | undefined,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    isFetched,
    refetch,
    error: sessionError,
    data: session,
    data: { refreshedAt } = {},
  } = trpc.auth.session.useQuery(undefined, {
    meta: { persist: false },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!refreshedAt) {
      return () => {};
    }

    const timeout = setTimeout(
      refetch,
      Math.min(
        2147483647,
        addDays(
          // HACK importing refreshTokenExpireDays from @sunblocks/trpc imports all the server-side deps into the client-side, not possible
          30,
          refreshedAt
        ).valueOf() - Date.now()
      )
    );

    return () => clearTimeout(timeout);
  }, [refetch, refreshedAt]);

  const utils = trpc.useUtils();

  const removeAuthedQueries = useCallback(() => {
    // TODO Is there a way to auto detect these?
    utils.users.me.reset();
    utils.scores.getAll.reset();
  }, [utils.scores.getAll, utils.users.me]);

  const isUnauthorized =
    sessionError instanceof TRPCClientError &&
    sessionError.data?.code === "UNAUTHORIZED";

  useEffect(() => {
    if (!isUnauthorized) {
      return;
    }

    removeAuthedQueries();
  }, [isUnauthorized, removeAuthedQueries]);

  const posthog = usePostHog();

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (event.data.type !== "auth-success") {
        return;
      }

      const {
        data: { session: sessionString },
      } = z
        .object({
          data: z.object({
            type: z.literal("auth-success"),
            session: z.string(),
          }),
        })
        .parse(event);

      const session = z
        .object({
          accessToken: z.string(),
          refreshedAt: z.date(),
          userId: z.string(),
        })
        .parse(SuperJSON.parse(sessionString));

      const { userId } = session;

      removeAuthedQueries();
      utils.auth.session.setData(undefined, session);

      posthog.identify(userId);
    };

    window.addEventListener("message", handler);

    return () => window.removeEventListener("message", handler);
  }, [posthog, removeAuthedQueries, utils.auth.session]);

  const login = useCallback(
    (provider: LoginProvider) =>
      window.open(
        `/api/auth/${provider}`,
        "_blank",
        (({ h, w }: { h: number; w: number }) => {
          // https://stackoverflow.com/a/16861050/4806413
          const dualScreenLeft =
            window.screenLeft !== undefined
              ? window.screenLeft
              : window.screenX;
          const dualScreenTop =
            window.screenTop !== undefined ? window.screenTop : window.screenY;

          const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            globalThis.screen.width;

          const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            globalThis.screen.height;

          const systemZoom = width / window.screen.availWidth;
          const left = (width - w) / 2 / systemZoom + dualScreenLeft;
          const top = (height - h) / 2 / systemZoom + dualScreenTop;

          return `scrollbars=yes,width=${w / systemZoom},height=${
            h / systemZoom
          },top=${top},left=${left}`;
        })({ h: 600, w: 400 })
      ) ||
      window.open(`/api/auth/${provider}`, "_blank", "popup") ||
      window.open(`/api/auth/${provider}`, "_blank"),
    []
  );

  const { mutateAsync: logoutTrpc } = trpc.auth.logout.useMutation();

  const logout = useCallback(async () => {
    posthog.reset();
    await logoutTrpc();
    await utils.auth.session.reset();
  }, [logoutTrpc, posthog, utils.auth.session]);

  const value = useMemo(
    () => ({
      login,
      logout,
      session: session ?? (isFetched ? null : undefined),
    }),
    [isFetched, login, logout, session]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
