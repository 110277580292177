import Image from "next/image";
import type { ComponentProps } from "react";
import type { z } from "zod";

import type { zUser } from "@sunblocks/trpc";

export const Avatar = ({
  className,
  user: { name, picture },
  alt = name ?? "Avatar",
  height = 48,
  src = picture,
  width = 48,
  ...props
}: Omit<ComponentProps<typeof Image>, "alt" | "src"> &
  Partial<Pick<ComponentProps<typeof Image>, "alt" | "src">> & {
    user: Partial<Pick<z.infer<typeof zUser>, "name">> &
      Pick<z.infer<typeof zUser>, "picture">;
  }) => (
  <Image
    {...props}
    alt={alt}
    className={`inline-block size-control rounded-md object-contain ${className}`}
    height={height}
    src={src}
    width={width}
  />
);
