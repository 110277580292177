import type { TRPCLink } from "@trpc/client";
import { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";
import { observable } from "@trpc/server/observable";

export const vercelLink =
  <Router extends AnyRouter>(): TRPCLink<Router> =>
  () =>
  ({ next, op }) =>
    observable((observer) =>
      next(op).subscribe({
        ...observer,
        error: (err) =>
          observer.error(
            !(err.cause instanceof SyntaxError) ||
              !(err.meta?.response instanceof Response)
              ? err
              : new TRPCClientError("Hosting Provider Error", {
                  cause: err.cause,
                  meta: {
                    ...err.meta,
                    status: err.meta.response.status,
                    vercelError:
                      err.meta.response.headers.get("x-vercel-error"),
                    vercelId: err.meta.response.headers.get("x-vercel-id"),
                  },
                })
          ),
      })
    );
