import { z } from "zod";

import { getBlockPositionByKey } from "./logic";
import type { LevelData } from "./models";
import { zPosition } from "./position";

export const zMove = z.object({
  _key: z.string(),
  position: zPosition,
});

export type Move = z.infer<typeof zMove>;

export const zMoveSet = z.tuple([z.array(zMove), z.array(zMove)]);

export type MoveSet = z.infer<typeof zMoveSet>;

export const addMoveOptimal = (
  { blocks }: Pick<LevelData, "blocks">,
  {
    blockPositionByKey,
  }: {
    blockPositionByKey: ReturnType<typeof getBlockPositionByKey>;
  },
  moveSet: MoveSet,
  consideredMove: Move
): MoveSet => {
  const [moves, redoMoves] = moveSet;

  if (
    redoMoves[0]?._key === consideredMove._key &&
    redoMoves[0]?.position[0] === consideredMove.position[0] &&
    redoMoves[0]?.position[1] === consideredMove.position[1]
  ) {
    // Equivalent to redo
    return [[...moves, ...redoMoves.slice(0, 1)], redoMoves.slice(1)];
  }

  const sameBlockAsLastMove = moves.at(-1)?._key === consideredMove._key;

  if (
    sameBlockAsLastMove &&
    moves.at(-1)?.position[0] === consideredMove.position[0] &&
    moves.at(-1)?.position[1] === consideredMove.position[1]
  ) {
    // Nothing changed, do nothing
    return moveSet;
  }

  const movesWithoutDuplicate = sameBlockAsLastMove
    ? moves.slice(0, -1)
    : moves;

  const { [consideredMove._key]: previousBlockPosition } = sameBlockAsLastMove
    ? getBlockPositionByKey({ blocks }, moves.slice(0, -1))
    : blockPositionByKey;

  const sameAsPreviousBlockPosition =
    previousBlockPosition?.[0] === consideredMove.position[0] &&
    previousBlockPosition?.[1] === consideredMove.position[1];

  return !sameAsPreviousBlockPosition
    ? // Something changed make the move without any duplicates
      [[...movesWithoutDuplicate, consideredMove], []]
    : sameBlockAsLastMove
    ? // Equivalent to an undo
      [movesWithoutDuplicate, [...moves.slice(-1), ...redoMoves]]
    : // Nothing changed, do nothing
      moveSet;
};
