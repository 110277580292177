import type { ReactNode } from "react";
import { Toaster } from "sonner";

export const ToastProvider = ({ children }: { children: ReactNode }) => (
  <>
    {children}
    <Toaster
      theme="system"
      toastOptions={{
        closeButton: true,
      }}
    />
  </>
);
