import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import VolumeOffRounded from "@mui/icons-material/VolumeOffRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import type { ComponentProps, SetStateAction } from "react";

import type { RouterOutputs } from "@sunblocks/trpc";

import { Avatar } from "../Avatar";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: { spacing },
} = tailwindConfig;

const Item = ({
  className,
  ...props
}: ComponentProps<typeof DropdownMenuPrimitive.Item>) => (
  <DropdownMenuPrimitive.Item
    {...props}
    className={`relative flex cursor-pointer select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-slate-100 focus:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&>svg]:size-4 [&>svg]:shrink-0 ${className}`}
  />
);

const Seperator = () => (
  <DropdownMenuPrimitive.Separator className="-mx-1 my-1 h-px bg-slate-100" />
);

export const MenuDropdown = ({
  login,
  logout,
  muted,
  onOpenLevelScores,
  session,
  setMuted,
  user,
}: {
  login: (provider: "google") => void;
  logout: () => void;
  muted: boolean | undefined;
  onOpenLevelScores?: () => void;
  session: RouterOutputs["auth"]["session"] | null | undefined;
  setMuted: (muted: SetStateAction<boolean>) => void;
  user?: RouterOutputs["users"]["me"];
}) => (
  <DropdownMenuPrimitive.Root>
    <DropdownMenuPrimitive.Trigger
      className={`size-menu transition-opacity duration-100 ${
        session === undefined && !user ? "opacity-0" : "opacity-100"
      }`}
    >
      {!user ? (
        <AccountBoxRoundedIcon
          style={{
            height: spacing.control,
            width: spacing.control,
          }}
        />
      ) : (
        <Avatar user={user} />
      )}
    </DropdownMenuPrimitive.Trigger>
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 mr-2 min-w-[8rem] overflow-hidden rounded-md border bg-white p-1 text-slate-900 shadow-md"
        sideOffset={4}
      >
        {!session && (
          <>
            <Item onSelect={() => login("google")}>Login with Google</Item>{" "}
            <Seperator />
          </>
        )}
        <Item
          onSelect={(event) => {
            setMuted((muted) => !muted);
            event.preventDefault();
          }}
        >
          {muted ? (
            <>
              <VolumeOffRounded /> Muted
            </>
          ) : (
            <>
              <VolumeUpRounded /> Sound On
            </>
          )}
        </Item>
        {onOpenLevelScores && (
          <>
            <Seperator />
            <Item onSelect={onOpenLevelScores}>
              <LeaderboardRoundedIcon /> Level Stats
            </Item>
          </>
        )}
        {session && (
          <>
            <Seperator />
            <Item onSelect={logout}>
              <LogoutRoundedIcon /> Logout
            </Item>
          </>
        )}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  </DropdownMenuPrimitive.Root>
);
