import { identity } from "lodash/fp";
import LZString from "lz-string";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { Dispatch, ReactNode, SetStateAction } from "react";

import { actionify, reuseReferences } from "@sunblocks/utils";

import { SuperJSON } from "src/superjson";

export const createStorage = <Schema,>(
  getStorage: () => Storage,
  key: string,
  schema: { parse: (data: unknown) => Schema }
) => {
  const StorageContext = createContext<
    | [Schema, Dispatch<SetStateAction<Schema>>, { hydrated: true }]
    | [undefined, Dispatch<SetStateAction<Schema>>, { hydrated: false }]
  >([
    undefined,
    () => {
      throw new Error("NEEDS TO BE IN A PROVIDER");
    },
    { hydrated: false },
  ]);

  const useStorage = () => useContext(StorageContext);

  const useScopedStorage = <Scoped,>(
    get: (stored: Schema) => Scoped,
    set: (stored: Schema, value: Scoped) => Schema = identity
  ) => {
    const [stored, setStored, { hydrated }] = useStorage();

    const scopedValue = useMemo(
      () => (!hydrated ? undefined : get(stored!)),
      [get, hydrated, stored]
    );

    const setScoped = useCallback(
      (prevScoped: SetStateAction<Scoped>) =>
        setStored((stored) => set(stored, actionify(prevScoped)(get(stored)))),
      [get, set, setStored]
    );

    return (
      hydrated
        ? [scopedValue!, setScoped, { hydrated }]
        : [undefined, setScoped, { hydrated }]
    ) satisfies
      | [Scoped, Dispatch<SetStateAction<Scoped>>, { hydrated: true }]
      | [undefined, Dispatch<SetStateAction<Scoped>>, { hydrated: false }];
  };

  const StorageProvider = ({ children }: { children: ReactNode }) => {
    const [hydrated, setHydrated] = useState(false);
    const [value, setValue] = useState<Schema>();

    useEffect(() => {
      if (hydrated) {
        return;
      }

      const rawValue = getStorage().getItem(key);
      const value = rawValue && LZString.decompress(rawValue);

      setValue(
        value === null ? undefined : schema.parse(SuperJSON.parse(value))
      );
      setHydrated(true);
    }, [hydrated]);

    useEffect(() => {
      const callback = ({
        storageArea,
        key: eventKey,
        newValue: newValueString,
      }: StorageEvent) => {
        if (storageArea !== getStorage() || eventKey !== key) {
          return;
        }

        setValue(
          reuseReferences(
            newValueString === null
              ? undefined
              : schema.parse(
                  SuperJSON.parse(LZString.decompress(newValueString))
                )
          )
        );
      };

      globalThis.addEventListener("storage", callback);

      return () => globalThis.removeEventListener("storage", callback);
    }, []);

    const setStored = useCallback(
      (prevStored: SetStateAction<Schema>) =>
        setValue((prevValue) => {
          const value = actionify(prevStored)(prevValue!);

          if (value === undefined) {
            getStorage().removeItem(key);
          } else if (value !== prevValue) {
            getStorage().setItem(
              key,
              LZString.compress(SuperJSON.stringify(value))
            );
          }

          return value;
        }),
      []
    );

    return (
      <StorageContext.Provider
        value={useMemo(
          () =>
            hydrated
              ? [value!, setStored, { hydrated }]
              : [undefined, setStored, { hydrated }],
          [hydrated, setStored, value]
        )}
      >
        {children}
      </StorageContext.Provider>
    );
  };

  return {
    StorageProvider,
    useScopedStorage,
    useStorage,
  };
};
