import type { AppType } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import "tailwindcss/tailwind.css";
import { z } from "zod";

import { HowlerSounds } from "@sunblocks/components";

import { AuthProvider } from "src/components/Auth";
import { TRPCProvider } from "src/components/TRPC";
import { ToastProvider } from "src/components/Toast";
import { LocalStorage } from "src/components/use-local-storage";
import { SessionStorage } from "src/components/use-session-storage";
import {
  NEXT_PUBLIC_POSTHOG_HOST,
  NEXT_PUBLIC_POSTHOG_KEY,
} from "src/posthog/env";
import type { serverTrpc } from "src/trpc/server";
import { VERCEL_PROJECT_PRODUCTION_URL } from "src/vercel/env";

const { NEXT_PUBLIC_HASH_ASSETS } = z
  .object({
    NEXT_PUBLIC_HASH_ASSETS: z.string().min(1),
  })
  .parse({
    NEXT_PUBLIC_HASH_ASSETS: process.env.NEXT_PUBLIC_HASH_ASSETS,
  });

if (
  // eslint-disable-next-line unicorn/prefer-global-this -- Specifically want to check for window
  typeof window !== "undefined" &&
  NEXT_PUBLIC_POSTHOG_HOST &&
  NEXT_PUBLIC_POSTHOG_KEY
) {
  posthog.init(NEXT_PUBLIC_POSTHOG_KEY, {
    // https://posthog.com/docs/advanced/proxy/nextjs
    api_host: "/ingest",
    // TODO Use new environment variables here
    ui_host: "https://us.posthog.com",
    debug: VERCEL_PROJECT_PRODUCTION_URL !== document.location.host,
    opt_out_capturing_by_default:
      VERCEL_PROJECT_PRODUCTION_URL !== document.location.host,
  });
}

export type PageProps = {
  [key: string]: unknown;
  trpcState?: ReturnType<ReturnType<typeof serverTrpc>["dehydrate"]>;
};

const App = (({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => posthog.capture("$pageview");

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Sun Blocks</title>

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
      </Head>
      <ToastProvider>
        <TRPCProvider trpcState={pageProps?.trpcState}>
          <PostHogProvider client={posthog}>
            <AuthProvider>
              <LocalStorage>
                <SessionStorage>
                  <HowlerSounds
                    prefix="/assets"
                    suffix={NEXT_PUBLIC_HASH_ASSETS}
                  >
                    <Component {...pageProps} />
                  </HowlerSounds>
                </SessionStorage>
              </LocalStorage>
            </AuthProvider>
          </PostHogProvider>
        </TRPCProvider>
      </ToastProvider>
    </>
  );
}) satisfies AppType<PageProps>;

export default App;
