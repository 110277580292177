import { z } from "zod";

import { zMove, zMoveSet } from "@sunblocks/game";

import { createStorage } from "./use-storage";

export const {
  StorageProvider: LocalStorage,
  useScopedStorage: useScopedLocalStorage,
} = createStorage(
  () => localStorage,
  "sunblocks",
  z
    .object({
      muted: z.boolean(),
      dailyStreaks: z.record(z.string(), z.string()),
      inProgress: z.record(
        z.string(),
        z.object({
          moveSet: zMoveSet,
          timeInLevel: z.number(),
          totalNumMoves: z.number(),
        })
      ),
      submitScoreQueue: z.array(
        z.object({
          levelId: z.string(),
          moves: z.array(zMove),
        })
      ),
    })
    .partial()
);
