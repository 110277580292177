import { useMemo, useSyncExternalStore } from "react";

const subscribe = (callback: () => void) => {
  globalThis.window.addEventListener("orientationchange", callback);
  globalThis.window.addEventListener("resize", callback);
  return () => {
    globalThis.window.removeEventListener("orientationchange", callback);
    globalThis.window.removeEventListener("resize", callback);
  };
};

export const useWindowDimensions = () => {
  const height = useSyncExternalStore(
    subscribe,
    () => window.innerHeight,
    () => 0
  );

  const width = useSyncExternalStore(
    subscribe,
    () => window.innerWidth,
    () => 0
  );

  return useMemo(() => ({ height, width }), [height, width]);
};
