import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { Hydrate, QueryClient, onlineManager } from "@tanstack/react-query";
import type { DehydratedState } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import type { PersistQueryClientOptions } from "@tanstack/react-query-persist-client";
import { TRPCClientError, httpBatchLink, loggerLink } from "@trpc/client";
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react";
import type { ReactNode } from "react";
import { toast } from "sonner";
import { z } from "zod";

import type { AppRouter } from "@sunblocks/trpc";

import { SuperJSON } from "src/superjson";
import { trpc } from "src/trpc/client";
import { VERCEL_ENV } from "src/vercel/env";
import { vercelLink } from "src/vercel/trpc";

const networkMode =
  process.env.NODE_ENV === "production"
    ? "online"
    : // localhost:3000 is offline, could still work
      "offlineFirst";

const isTRPCClientError = (
  cause: unknown
): cause is TRPCClientError<AppRouter> => cause instanceof TRPCClientError;

const isServerError = (
  err: TRPCClientError<AppRouter>
): err is TRPCClientError<AppRouter> & { data: { httpStatus: number } } =>
  !err.data || err.data.httpStatus >= 500;

const onError = (err: unknown) =>
  isTRPCClientError(err) && !isServerError(err)
    ? undefined
    : toast.error("Something went wrong!", {
        description: !(err instanceof Error) ? undefined : err.message,
        classNames: {
          icon: "text-red-500",
        },
      });

export const TRPCProvider = ({
  children,
  trpcState,
}: {
  children: ReactNode;
  trpcState?: DehydratedState;
}) => {
  const isOnline = useSyncExternalStore(
    onlineManager.subscribe,
    () => onlineManager.isOnline(),
    () => true
  );
  const wasOnline = useDeferredValue(isOnline);

  useEffect(() => {
    if (isOnline === wasOnline) {
      return;
    }

    if (isOnline) {
      toast.success("Back Online!", {
        id: "trpc-online",
        icon: <WifiRoundedIcon />,
        classNames: {
          icon: "text-green-500",
        },
      });
    } else {
      toast.error("You're Offline", {
        id: "trpc-online",
        description:
          "We'll try to keep your progress for when you get back online.",
        icon: <WifiOffRoundedIcon />,
        classNames: {
          icon: "text-red-500",
        },
      });
    }
  }, [isOnline, wasOnline]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode,
            onError,
            cacheTime: Infinity,
            staleTime: 1000 * 60 * 60 * 24,
          },
          mutations: {
            networkMode,
            onError,
            cacheTime: Infinity,
          },
        },
      })
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: SuperJSON,
      links: [
        loggerLink({
          enabled: (opts) =>
            (VERCEL_ENV !== "production" &&
              // eslint-disable-next-line unicorn/prefer-global-this -- Specifically want to check for window
              typeof window !== "undefined") ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        vercelLink(),
        httpBatchLink({
          url: "/api/trpc",
          headers: ({ opList }) =>
            opList.reduce(
              (headers, { context: { headers: opHeaders } }) => ({
                ...headers,
                ...z
                  .record(
                    z.string(),
                    z.union([z.string(), z.array(z.string())])
                  )
                  .safeParse(opHeaders).data,
              }),
              {} as { [key: string]: MaybeArray<string> }
            ),
        }),
      ],
    })
  );

  const dehydratedState = trpc.useDehydratedState(trpcClient, trpcState);

  const [persister] = useState(() =>
    createSyncStoragePersister({
      storage:
        process.env.NODE_ENV !== "production"
          ? "sessionStorage" in globalThis
            ? globalThis.sessionStorage
            : undefined
          : "localStorage" in globalThis
          ? globalThis.localStorage
          : undefined,
    })
  );

  const persistOptions = useMemo(
    (): Omit<PersistQueryClientOptions, "queryClient"> => ({
      persister,
      dehydrateOptions: {
        shouldDehydrateQuery: ({
          state: { status },
          meta: { persist = true } = {},
        }) => status === "success" && Boolean(persist),
      },
    }),
    [persister]
  );

  const onPersisterSuccess = useCallback(
    async () => queryClient.resumePausedMutations(),
    [queryClient]
  );

  return (
    <trpc.Provider
      abortOnUnmount
      client={trpcClient}
      queryClient={queryClient}
      ssrState={false}
      ssrContext={null}
    >
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={persistOptions}
        onSuccess={onPersisterSuccess}
      >
        <Hydrate state={dehydratedState}>{children}</Hydrate>
      </PersistQueryClientProvider>
    </trpc.Provider>
  );
};
