import { useSyncExternalStore } from "react";

export const useWindowFocus = () =>
  useSyncExternalStore(
    (callback) => {
      window.addEventListener("blur", callback);
      window.addEventListener("focus", callback);

      return () => {
        window.removeEventListener("blur", callback);
        window.removeEventListener("focus", callback);
      };
    },
    () => document.hasFocus(),
    () => true
  );
