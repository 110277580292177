import { motion } from "framer-motion";
import { flatMap, groupBy, keyBy, mapValues, pick, sortBy } from "lodash/fp";
import {
  memo,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { ComponentProps, SetStateAction } from "react";

import {
  addMoveOptimal,
  getActiveByKey,
  getActiveNodes,
  getAdjacentBlocksByKey,
  getAdjacentNodesByKey,
  getBlockByPosition,
  getBlockPositionByKey,
  getCellByPosition,
  getFillByKey,
  getFillsByKey,
  getNodesByFill,
  getNodesByFillOfSource,
  getSinksByFill,
  getSourcesByFill,
  getValidPositions,
  isWin,
  sumPositions,
  toPositionMap,
} from "@sunblocks/game";
import type {
  Block,
  Cell,
  Fill,
  LevelData,
  MoveSet,
  Node,
  Position,
} from "@sunblocks/game";
import { fp } from "@sunblocks/utils";

import { remToNum } from "../../utils";
import type { BackgroundType } from "../Background";
import { BlockModel } from "../BlockModel";
import { CellModel } from "../CellModel";
import { MotionDiv } from "../Motion";
import { tailwindConfig } from "../tailwind-config";
import { useDocumentVisibility } from "../use-document-visibility-state";
import { howlerOptions, useHowler } from "../use-howler";
import { useVibrate } from "../use-vibrate";
import { useWindowDimensions } from "../use-window-dimensions";
import { useWindowFocus } from "../use-window-focus";
import { usePointerController } from "./use-pointer-controller";
import { sumCoordinates } from "./utils";
import type { Coordinate } from "./utils";

const {
  theme: { spacing },
} = tailwindConfig;

const defaultMoveSet = [[], []] satisfies MoveSet;

const tabIndexOffset = 1;

const LevelPure = ({
  className,
  levelData,
  onHiddenComplete,
  onVisibleComplete,
  onWin,
  onWinComplete,
  animating = true,
  background = "default",
  bestScore = Infinity,
  canInteract: canInteractProp = true,
  immediate = false,
  moveSet: [moves] = defaultMoveSet,
  muted: mutedProp = false,
  setMoveSet = () => {},
  setVisibleComplete: setVisibleCompleteProp,
  style: styleProp,
  visible = true,
  levelData: { blocks, cells, gridHeight, gridWidth },
  ...props
}: ComponentProps<typeof motion.div> & {
  animating?: boolean;
  background?: BackgroundType;
  bestScore?: number;
  canInteract?: boolean;
  immediate?: boolean;
  levelData: Pick<LevelData, "blocks" | "cells" | "gridHeight" | "gridWidth">;
  moveSet?: MoveSet;
  muted?: boolean;
  onHiddenComplete?: () => void;
  onVisibleComplete?: () => void;
  onWin?: () => void;
  onWinComplete?: () => void;
  setMoveSet?: (moveSet: SetStateAction<MoveSet>) => void;
  setVisibleComplete?: (visibleComplete: SetStateAction<boolean>) => void;
  visible?: boolean;
}) => {
  const [visibleComplete, setVisibleCompleteState] = useState(false);
  const setVisibleComplete = useCallback(
    (visibleComplete: SetStateAction<boolean>) => {
      setVisibleCompleteState(visibleComplete);
      setVisibleCompleteProp?.(visibleComplete);
    },
    [setVisibleCompleteProp]
  );

  const positionToCoordinate = useCallback(
    (position: Position): Coordinate => ({
      top:
        remToNum(spacing.betweenBlockAndCell) *
        (position[0] + 0.5 - gridHeight / 2),
      left:
        remToNum(spacing.betweenBlockAndCell) *
        (position[1] + 0.5 - gridWidth / 2),
    }),
    [gridHeight, gridWidth]
  );

  const blockPositionByKey = useMemo(
    () => getBlockPositionByKey({ blocks }, moves),
    [blocks, moves]
  );

  const blockByPosition = useMemo(
    () => getBlockByPosition({ blocks }, { blockPositionByKey }),
    [blockPositionByKey, blocks]
  );

  const adjacentBlocksByKey = useMemo(
    () =>
      getAdjacentBlocksByKey(
        { blocks },
        { blockByPosition, blockPositionByKey }
      ),
    [blockByPosition, blockPositionByKey, blocks]
  );

  const cellByPosition = useMemo(() => getCellByPosition({ cells }), [cells]);

  const adjacentNodesByKey = useMemo(
    () =>
      getAdjacentNodesByKey(
        { blocks, cells },
        {
          adjacentBlocksByKey,
          blockByPosition,
          blockPositionByKey,
          cellByPosition,
        }
      ),
    [
      adjacentBlocksByKey,
      blockByPosition,
      blockPositionByKey,
      blocks,
      cellByPosition,
      cells,
    ]
  );

  const activeByKey = useMemo(
    () =>
      getActiveByKey(
        { blocks, cells },
        { adjacentBlocksByKey, adjacentNodesByKey }
      ),
    [adjacentBlocksByKey, adjacentNodesByKey, blocks, cells]
  );

  const activeNodes = useMemo(
    () => getActiveNodes({ blocks, cells }, { activeByKey }),
    [activeByKey, blocks, cells]
  );

  const fillsByKey = useMemo(
    () =>
      getFillsByKey({
        activeByKey,
        activeNodes,
        adjacentNodesByKey,
      }),
    [activeByKey, activeNodes, adjacentNodesByKey]
  );

  const fillByKey = useMemo(() => getFillByKey({ fillsByKey }), [fillsByKey]);

  const nodesByFill = useMemo(
    () => getNodesByFill({ activeNodes, fillByKey }),
    [activeNodes, fillByKey]
  );

  const sinksByFill = useMemo(
    () => getSinksByFill({ blocks, cells }),
    [blocks, cells]
  );

  const won = useMemo(
    () => isWin({ nodesByFill, sinksByFill }),
    [nodesByFill, sinksByFill]
  );

  const windowDimensions = useWindowDimensions();
  const scale = useMemo(
    () =>
      Math.min(
        1,
        (windowDimensions.height / 16 -
          2 * remToNum(spacing.menu) -
          2 * remToNum(spacing.distanceBetween)) /
          (remToNum(spacing.betweenBlockAndCell) * gridHeight),
        (windowDimensions.width / 16 - 2 * remToNum(spacing.distanceBetween)) /
          (remToNum(spacing.betweenBlockAndCell) * gridWidth)
      ),
    [gridHeight, gridWidth, windowDimensions.height, windowDimensions.width]
  );

  const style = useMemo(
    () => ({
      ...styleProp,
      scale,
      height: `${100 / scale}%`,
      width: `${100 / scale}%`,
    }),
    [scale, styleProp]
  );

  const sourcesByFill = useMemo(
    () => getSourcesByFill({ blocks, cells }),
    [blocks, cells]
  );

  const [visibleNodesByFillRaw, setVisibleNodesByFill] = useState(() =>
    immediate
      ? nodesByFill
      : {
          ...sourcesByFill,
          unfilled: activeNodes
            .difference(sourcesByFill.fire)
            .difference(sourcesByFill.sun)
            .difference(sourcesByFill.water),
        }
  );

  const visibleNodesByFill = immediate ? nodesByFill : visibleNodesByFillRaw;

  const visibilityState = useDocumentVisibility();
  const windowFocus = useWindowFocus();

  const muted = mutedProp || visibilityState !== "visible" || !windowFocus;

  const playBlockDrop = useVibrate(
    useHowler({ preload: !muted, ...howlerOptions.blockDrop })[0],
    50
  );

  const [consideringController, setConsideringController] = useState<string>();
  const [consideredBlock, setConsideredBlock] = useState<Block>();
  const [consideredPosition, setConsideredPosition] = useState<Position>();

  const onBlockCommit = useCallback(() => {
    if (!consideredBlock || !consideredPosition) {
      return;
    }

    setMoveSet((moveSet) =>
      addMoveOptimal({ blocks }, { blockPositionByKey }, moveSet, {
        _key: consideredBlock._key,
        position: consideredPosition,
      })
    );

    playBlockDrop();
    setConsideringController(undefined);
    setConsideredBlock(undefined);
    setConsideredPosition(undefined);
  }, [
    blockPositionByKey,
    blocks,
    consideredBlock,
    consideredPosition,
    playBlockDrop,
    setMoveSet,
  ]);

  const consideredMoves = useMemo(
    () =>
      !consideredBlock || !consideredPosition
        ? moves
        : [
            ...moves,
            { _key: consideredBlock._key, position: consideredPosition },
          ],
    [consideredBlock, consideredPosition, moves]
  );

  const consideredBlockPositionByKey = useMemo(
    () =>
      consideredMoves === moves
        ? blockPositionByKey
        : getBlockPositionByKey({ blocks }, consideredMoves),
    [blockPositionByKey, blocks, consideredMoves, moves]
  );

  const consideredBlockByPosition = useMemo(
    () =>
      consideredMoves === moves
        ? blockByPosition
        : getBlockByPosition(
            { blocks },
            { blockPositionByKey: consideredBlockPositionByKey }
          ),
    [
      blockByPosition,
      blocks,
      consideredBlockPositionByKey,
      consideredMoves,
      moves,
    ]
  );

  const consideredAdjacentBlocksByKey = useMemo(
    () =>
      consideredMoves === moves
        ? adjacentBlocksByKey
        : getAdjacentBlocksByKey(
            { blocks },
            {
              blockByPosition: consideredBlockByPosition,
              blockPositionByKey: consideredBlockPositionByKey,
            }
          ),
    [
      adjacentBlocksByKey,
      blocks,
      consideredBlockByPosition,
      consideredBlockPositionByKey,
      consideredMoves,
      moves,
    ]
  );

  const consideredAdjacentNodesByKey = useMemo(
    () =>
      consideredMoves === moves
        ? adjacentNodesByKey
        : getAdjacentNodesByKey(
            { blocks, cells },
            {
              cellByPosition,
              adjacentBlocksByKey: consideredAdjacentBlocksByKey,
              blockByPosition: consideredBlockByPosition,
              blockPositionByKey: consideredBlockPositionByKey,
            }
          ),
    [
      adjacentNodesByKey,
      blocks,
      cellByPosition,
      cells,
      consideredAdjacentBlocksByKey,
      consideredBlockByPosition,
      consideredBlockPositionByKey,
      consideredMoves,
      moves,
    ]
  );

  const consideredActiveByKey = useMemo(
    () =>
      consideredMoves === moves
        ? activeByKey
        : getActiveByKey(
            { blocks, cells },
            {
              adjacentBlocksByKey: consideredAdjacentBlocksByKey,
              adjacentNodesByKey: consideredAdjacentNodesByKey,
            }
          ),
    [
      activeByKey,
      blocks,
      cells,
      consideredAdjacentBlocksByKey,
      consideredAdjacentNodesByKey,
      consideredMoves,
      moves,
    ]
  );

  const consideredActiveNodes = useMemo(
    () =>
      consideredMoves === moves
        ? activeNodes
        : getActiveNodes(
            { blocks, cells },
            { activeByKey: consideredActiveByKey }
          ),
    [activeNodes, blocks, cells, consideredActiveByKey, consideredMoves, moves]
  );

  const consideredFillsByKey = useMemo(
    () =>
      consideredMoves === moves
        ? fillsByKey
        : getFillsByKey({
            activeByKey: consideredActiveByKey,
            activeNodes: consideredActiveNodes,
            adjacentNodesByKey: consideredAdjacentNodesByKey,
          }),
    [
      consideredActiveByKey,
      consideredActiveNodes,
      consideredAdjacentNodesByKey,
      consideredMoves,
      fillsByKey,
      moves,
    ]
  );

  const consideredNodesByFill = useMemo(
    () =>
      consideredMoves === moves
        ? nodesByFill
        : getNodesByFill({
            activeNodes: consideredActiveNodes,
            fillByKey: getFillByKey({ fillsByKey: consideredFillsByKey }),
          }),
    [
      consideredActiveNodes,
      consideredFillsByKey,
      consideredMoves,
      moves,
      nodesByFill,
    ]
  );

  const nodesByFillOfSource = useMemo(
    () =>
      getNodesByFillOfSource({
        activeNodes,
        fillsByKey,
      }),
    [activeNodes, fillsByKey]
  );

  const consideredNodesByFillOfSource = useMemo(
    () =>
      consideredMoves === moves
        ? nodesByFillOfSource
        : getNodesByFillOfSource({
            activeNodes: consideredActiveNodes,
            fillsByKey: consideredFillsByKey,
          }),
    [
      consideredActiveNodes,
      consideredFillsByKey,
      consideredMoves,
      moves,
      nodesByFillOfSource,
    ]
  );

  const animatingNodesByFill = useMemo(
    () =>
      !visible || !visibleComplete || immediate
        ? visibleNodesByFill
        : fp(
            ["fire", "sun", "water"] satisfies Fill[],
            keyBy((fill) => fill),
            mapValues((fill) =>
              (consideredBlock
                ? visibleNodesByFill[fill]
                : [...visibleNodesByFill[fill]].reduce(
                    (acc, { _key }) => acc.union(adjacentNodesByKey[_key]!),
                    visibleNodesByFill[fill]
                  )
              )
                .union(sourcesByFill[fill])
                .intersection(consideredNodesByFillOfSource[fill])
            ),
            pick(["fire", "sun", "water"]),
            ({ fire, sun, water }) => ({
              water,
              fire: fire.difference(water),
              sun: sun.difference(water).difference(fire),
            })
          ),
    [
      adjacentNodesByKey,
      consideredBlock,
      consideredNodesByFillOfSource,
      immediate,
      sourcesByFill,
      visible,
      visibleComplete,
      visibleNodesByFill,
    ]
  );

  const [hoveredBlock, setHoveredBlock] = useState<Block>();

  const onBlockHover = useCallback((block: Block | undefined) => {
    setHoveredBlock(block);
  }, []);

  const possibleBlock = consideredBlock ?? hoveredBlock;

  const validPositions = useMemo(
    () =>
      !possibleBlock
        ? []
        : getValidPositions(
            possibleBlock,
            blockPositionByKey[possibleBlock._key]!,
            { blockByPosition, cellByPosition }
          ),
    [blockByPosition, blockPositionByKey, cellByPosition, possibleBlock]
  );

  const validPositionMap = useMemo(
    () =>
      toPositionMap(
        validPositions,
        (position) => position,
        () => true as const
      ),
    [validPositions]
  );

  const playBlockLift = useVibrate(
    useHowler({ preload: !muted, ...howlerOptions.blockLift })[0],
    50
  );

  const onBlockConsider = useCallback(
    (...args: [] | [controller: string, block: Block, position: Position]) => {
      if (args.length === 0) {
        if (consideredBlock) {
          playBlockDrop();
        }
        setConsideringController(undefined);
        setConsideredBlock(undefined);
        setConsideredPosition(undefined);
        return;
      }

      const [controller, block, position] = args;

      if (consideredBlock !== block) {
        if (consideredBlock) {
          onBlockCommit();
        }
        playBlockLift();
        // "Locks in" currently animating nodes so they don't "revert" when grabbing things
        setVisibleNodesByFill((visibleNodesByFill) => ({
          ...animatingNodesByFill,
          unfilled: visibleNodesByFill.unfilled,
        }));
      }

      setConsideringController(controller);
      setConsideredBlock(block);

      const usableValidPositionMap =
        consideredBlock === block
          ? validPositionMap
          : toPositionMap(
              getValidPositions(block, blockPositionByKey[block._key]!, {
                blockByPosition,
                cellByPosition,
              }),
              (position) => position,
              () => true as const
            );

      setConsideredPosition((consideredPosition) =>
        (consideredPosition?.[0] === position[0] &&
          consideredPosition?.[1] === position[1]) ||
        !usableValidPositionMap?.[position[0]]?.[position[1]]
          ? consideredPosition
          : position
      );
    },
    [
      animatingNodesByFill,
      blockByPosition,
      blockPositionByKey,
      cellByPosition,
      consideredBlock,
      onBlockCommit,
      playBlockDrop,
      playBlockLift,
      validPositionMap,
    ]
  );

  // TODO Unravel usePointerController into Level, no reason for it to be separate
  const { controlledBlocks, blockProps: blockPropsForPointer } =
    usePointerController({
      consideredBlock,
      consideringController,
      onBlockCommit,
      onBlockConsider,
      onBlockHover,
      positionToCoordinate,
      remPx: 16 * scale,
      consideredBlockValidPositions: validPositions,
      consideredBlockCoordinate:
        consideredBlock &&
        positionToCoordinate(blockPositionByKey[consideredBlock._key]!),
    });

  const blockPropsForKeyboard = useCallback(
    (block: Block, { position, position: [y, x] }: { position: Position }) =>
      ({
        tabIndex: y * gridWidth + x + (tabIndexOffset ?? 1),
        onFocus: () => onBlockHover(block),
        onBlur: () => {
          // HACK This will only commit/cancel a keyboard consideration
          // The issue is that we can't tell if we focus/blur from keyboard or mouse
          // Any solution for this is a bad hack
          if (consideredBlock && consideringController === "keyboard") {
            onBlockCommit();
          }
          onBlockHover(undefined);
        },
        onKeyDown: ({ altKey, ctrlKey, metaKey, shiftKey, key }) => {
          if (altKey || ctrlKey || metaKey || shiftKey) {
            return;
          }

          if (
            !consideredBlock &&
            (key === "Enter" ||
              key === " " ||
              key === "ArrowUp" ||
              key === "ArrowDown" ||
              key === "ArrowLeft" ||
              key === "ArrowRight")
          ) {
            onBlockConsider("keyboard", block, position);
          }

          if (key === "Enter" || key === " ") {
            if (!consideredBlock) {
              onBlockConsider("keyboard", block, position);
            } else {
              onBlockCommit();
            }
            return;
          }

          if (
            key === "ArrowUp" ||
            key === "ArrowDown" ||
            key === "ArrowLeft" ||
            key === "ArrowRight"
          ) {
            onBlockConsider(
              "keyboard",
              block,
              (
                {
                  ArrowUp: [y - 1, x],
                  ArrowDown: [y + 1, x],
                  ArrowLeft: [y, x - 1],
                  ArrowRight: [y, x + 1],
                } satisfies { [key: string]: Position }
              )[key]
            );
            return;
          }

          if (consideredBlock && key === "Escape") {
            onBlockConsider();
          }
        },
      } satisfies Partial<ComponentProps<typeof BlockModel>>),
    [
      consideredBlock,
      consideringController,
      gridWidth,
      onBlockCommit,
      onBlockConsider,
      onBlockHover,
    ]
  );

  // HACK Nothing seems to prevent onWin from firing an infinite amount of times if it includes a useMutation
  // Attempted to useDeferredValue, useState, useMemo(() => onWin, []), nothing works
  const wonBefore = useRef(false);
  useEffect(() => {
    if (!won || wonBefore.current) {
      return;
    }

    wonBefore.current = true;
    setHoveredBlock(undefined);
    setConsideringController(undefined);
    setConsideredBlock(undefined);
    setConsideredPosition(undefined);
    onWin?.();
  }, [onWin, won]);

  const [
    playBackgroundSound,
    { fade: fadeBackgroundSound, stop: stopBackgroundSound },
  ] = useHowler({
    src: `/level-background-${
      ["fire", "night"].includes(background) ? background : "default"
    }.wav`,
    preload: !mutedProp,
    ...howlerOptions.levelBackground,
  });

  useEffect(() => {
    playBackgroundSound();

    return () => {
      stopBackgroundSound();
    };
  }, [playBackgroundSound, stopBackgroundSound]);

  useEffect(() => {
    if (muted || !visible || !visibleComplete) {
      return () => {};
    }

    fadeBackgroundSound(0, 0.2, 2000);

    return () => {
      fadeBackgroundSound(0.2, 0, 1000);
    };
  }, [fadeBackgroundSound, muted, visible, visibleComplete]);

  const highlighted = useMemo(
    () =>
      !possibleBlock
        ? {}
        : toPositionMap(
            validPositions.flatMap((position) =>
              possibleBlock.shape.map((shapePosition) =>
                sumPositions(position, shapePosition)
              )
            ),
            (position) => position,
            () => true as const
          ),
    [possibleBlock, validPositions]
  );

  const shadow = useMemo(
    () =>
      !consideredPosition || !consideredBlock
        ? {}
        : toPositionMap(
            consideredBlock.shape,
            (position) => sumPositions(position, consideredPosition),
            () => true as const
          ),
    [consideredBlock, consideredPosition]
  );

  const visibleSettled = useMemo(
    () =>
      !consideredBlock &&
      nodesByFill.fire.size === visibleNodesByFill.fire.size &&
      nodesByFill.sun.size === visibleNodesByFill.sun.size &&
      nodesByFill.water.size === visibleNodesByFill.water.size &&
      nodesByFill.unfilled.size === visibleNodesByFill.unfilled.size &&
      [...nodesByFill.fire].every((node) =>
        visibleNodesByFill.fire.has(node)
      ) &&
      [...nodesByFill.sun].every((node) => visibleNodesByFill.sun.has(node)) &&
      [...nodesByFill.water].every((node) =>
        visibleNodesByFill.water.has(node)
      ) &&
      [...nodesByFill.unfilled].every((node) =>
        visibleNodesByFill.unfilled.has(node)
      ),
    [
      consideredBlock,
      nodesByFill.fire,
      nodesByFill.sun,
      nodesByFill.unfilled,
      nodesByFill.water,
      visibleNodesByFill.fire,
      visibleNodesByFill.sun,
      visibleNodesByFill.unfilled,
      visibleNodesByFill.water,
    ]
  );

  const visibleSettledBefore = useDeferredValue(visibleSettled);

  useEffect(() => {
    if (!visibleSettled || visibleSettledBefore || !won) {
      return;
    }

    onWinComplete?.();
  }, [onWinComplete, visibleSettled, visibleSettledBefore, won]);

  const visibleFillByKey = useMemo(
    () =>
      fp(
        visibleNodesByFill,
        Object.entries<
          (typeof visibleNodesByFill)[keyof typeof visibleNodesByFill]
        >,
        flatMap(([fill, set]) =>
          [...set].map(
            ({ _key }) =>
              [_key, fill as keyof typeof visibleNodesByFill] satisfies [
                string,
                keyof typeof visibleNodesByFill
              ]
          )
        ),
        Object.fromEntries<keyof typeof visibleNodesByFill>
      ),
    [visibleNodesByFill]
  );

  // While moving around the consideredBlock, we need all the consideredNodesByFill.unfilled to "stick"
  useEffect(
    () =>
      setVisibleNodesByFill((visibleNodesByFill) => {
        const intersection = {
          unfilled: consideredNodesByFill.unfilled,
          fire: visibleNodesByFill.fire.intersection(animatingNodesByFill.fire),
          sun: visibleNodesByFill.sun.intersection(animatingNodesByFill.sun),
          water: visibleNodesByFill.water.intersection(
            animatingNodesByFill.water
          ),
        };

        return intersection.fire.size === visibleNodesByFill.fire.size &&
          intersection.sun.size === visibleNodesByFill.sun.size &&
          intersection.water.size === visibleNodesByFill.water.size
          ? visibleNodesByFill
          : intersection;
      }),
    [
      animatingNodesByFill.fire,
      animatingNodesByFill.sun,
      animatingNodesByFill.water,
      consideredBlock,
      consideredNodesByFill.unfilled,
      setVisibleNodesByFill,
    ]
  );

  const animatingFillByKey = useMemo(
    () =>
      fp(
        animatingNodesByFill,
        Object.entries<
          (typeof animatingNodesByFill)[keyof typeof animatingNodesByFill]
        >,
        flatMap(([fill, set]) =>
          [...set].map(
            ({ _key }) =>
              [_key, fill as keyof typeof animatingNodesByFill] satisfies [
                string,
                keyof typeof animatingNodesByFill
              ]
          )
        ),
        Object.fromEntries<keyof typeof animatingNodesByFill>
      ),
    [animatingNodesByFill]
  );

  // Active nodes that are either not satisfied or are sinks that aren't right
  const visuallyUnsatisfiedActiveNodes = useMemo(
    () => [
      ...activeNodes
        .difference(visibleNodesByFill.sun)
        .difference(visibleNodesByFill.fire)
        .difference(visibleNodesByFill.water)
        .difference(sinksByFill.unfilled)
        .union(sinksByFill.sun.difference(visibleNodesByFill.sun))
        .union(sinksByFill.unfilled.difference(visibleNodesByFill.unfilled))
        .union(sinksByFill.water.difference(visibleNodesByFill.water)),
    ],
    [
      activeNodes,
      sinksByFill.sun,
      sinksByFill.unfilled,
      sinksByFill.water,
      visibleNodesByFill.fire,
      visibleNodesByFill.sun,
      visibleNodesByFill.unfilled,
      visibleNodesByFill.water,
    ]
  );

  const doneAfterAnimating = useMemo(
    () =>
      isWin({
        sinksByFill,
        nodesByFill: { ...visibleNodesByFill, ...animatingNodesByFill },
      }),
    [animatingNodesByFill, sinksByFill, visibleNodesByFill]
  );

  const visibleDelay = useMemo(() => {
    const gapBetweenCells = 0.04;
    const gapBetweenBlocks = 0.05;
    const gapBetweenSets = 0.75;
    const initialDelay = 0.5;

    const cellDelay = ({ position: [y, x] }: Pick<Cell, "position">) =>
      initialDelay + gapBetweenCells * (y + x);

    const cellVisibleDelay = fp(
      cells,
      keyBy(({ _key }) => _key),
      mapValues(cellDelay)
    );

    const cellsCompleteAfter = cellDelay({
      position: [gridHeight, gridWidth],
    });

    const blocksSet = new Set(blocks);

    const n = new Set([...blocks].filter(({ n }) => n));

    const sets = [
      blocksSet.intersection(
        sourcesByFill.sun.union(sinksByFill.sun) as Set<Block>
      ),
      blocksSet.intersection(
        sourcesByFill.water.union(sinksByFill.water) as Set<Block>
      ),
      blocksSet.intersection(
        sinksByFill.unfilled.union(sinksByFill.unfilled) as Set<Block>
      ),
      n,
      new Set([...blocks].filter(({ mobile }) => !mobile)),
      blocksSet,
    ];

    return {
      ...cellVisibleDelay,
      ...sets
        .map((set, index) =>
          sets
            .slice(0, index)
            .reduce((set, previousSet) => set.difference(previousSet), set)
        )
        .filter(({ size }) => size)
        .reduce(
          ({ previousDelay, obj }, set) => {
            const nodeGroups = fp(
              [...set],
              groupBy(
                ({ shape, initialPosition: [y, x] }) =>
                  y +
                  x +
                  (Math.max(...shape.map(([y]) => y)) +
                    Math.max(...shape.map(([, x]) => x))) /
                    2
              ),
              Object.entries<Block[]>
            );

            return {
              previousDelay:
                previousDelay +
                gapBetweenSets +
                gapBetweenBlocks * nodeGroups.length,
              obj: {
                ...obj,
                ...fp(
                  nodeGroups,
                  sortBy(([distance]) => Number(distance)),
                  // I would have used lodash/fp/flatMap, but I want the index and I was lazy
                  (nodeGroups) =>
                    nodeGroups.flatMap(([, nodes], nodeGroupIndex) =>
                      nodes.map(
                        ({ _key }) =>
                          [
                            _key,
                            previousDelay + gapBetweenBlocks * nodeGroupIndex,
                          ] satisfies [string, number]
                      )
                    ),
                  Object.fromEntries<number>
                ),
              },
            };
          },
          {
            previousDelay: cellsCompleteAfter + gapBetweenSets,
            obj: {} as { [id: string]: number },
          }
        ).obj,
    };
  }, [
    blocks,
    cells,
    gridHeight,
    gridWidth,
    sinksByFill.sun,
    sinksByFill.unfilled,
    sinksByFill.water,
    sourcesByFill.sun,
    sourcesByFill.water,
  ]);

  const hiddenDelay = useMemo(() => {
    const gapBetweenNodes = 0.05;
    const gapBetweenSets = 0.75;
    const initialDelay = 0.5;

    const nodesSet = new Set([...blocks, ...cells]);

    const sets = [
      nodesSet
        .difference(nodesByFill.fire)
        .difference(nodesByFill.sun)
        .difference(nodesByFill.water)
        .difference(sinksByFill.sun)
        .difference(sinksByFill.unfilled)
        .difference(sinksByFill.water),
      nodesSet,
    ];

    return sets
      .map((set, index) =>
        sets
          .slice(0, index)
          .reduce((set, previousSet) => set.difference(previousSet), set)
      )
      .filter(({ size }) => size)
      .reduce(
        ({ previousDelay, obj }, set) => {
          const nodeGroups = fp(
            [...set],
            groupBy((node) => {
              if ("initialPosition" in node) {
                const { _key, shape } = node;

                const [y, x] = blockPositionByKey[_key]!;

                return (
                  y +
                  x +
                  (Math.max(...shape.map(([y]) => y)) +
                    Math.max(...shape.map(([, x]) => x))) /
                    2
                );
              }

              const {
                position: [y, x],
              } = node;

              return y + x;
            }),
            Object.entries<Node[]>
          );

          return {
            previousDelay:
              previousDelay +
              gapBetweenSets +
              gapBetweenNodes * nodeGroups.length,
            obj: {
              ...obj,
              ...fp(
                nodeGroups,
                sortBy(([distance]) => Number(distance)),
                // I would have used lodash/fp/flatMap, but I want the index and I was lazy
                (nodeGroups) =>
                  nodeGroups.flatMap(([, nodes], nodeGroupIndex) =>
                    nodes.map(
                      ({ _key }) =>
                        [
                          _key,
                          previousDelay + gapBetweenNodes * nodeGroupIndex,
                        ] satisfies [string, number]
                    )
                  ),
                Object.fromEntries<number>
              ),
            },
          };
        },
        {
          previousDelay: initialDelay,
          obj: {} as { [id: string]: number },
        }
      ).obj;
  }, [
    blockPositionByKey,
    blocks,
    cells,
    nodesByFill.fire,
    nodesByFill.sun,
    nodesByFill.water,
    sinksByFill.sun,
    sinksByFill.unfilled,
    sinksByFill.water,
  ]);

  const canInteract = visible && visibleComplete && canInteractProp;

  return (
    <motion.div
      {...props}
      className={`fixed flex items-center justify-center overflow-hidden ${className}`}
      style={style}
    >
      <MotionDiv
        className={`relative drop-shadow-lg ${
          canInteract ? "" : "pointer-events-none"
        }`}
        initial={immediate && visible ? "visible" : "hidden"}
        animate={[visible ? "visible" : "hidden"].filter(Boolean)}
        onAnimationComplete={(definition) => {
          if (definition !== "visible" && definition !== "hidden") {
            return;
          }

          const visibleCompleteNew = definition === "visible";

          if (visibleCompleteNew === visibleComplete) {
            return;
          }

          setVisibleComplete(visibleCompleteNew);
          (visibleCompleteNew ? onVisibleComplete : onHiddenComplete)?.();
        }}
      >
        {cells.map((cell) => {
          const coordinate = sumCoordinates(
            positionToCoordinate(cell.position),
            {
              top: -remToNum(spacing.cell) / 2,
              left: -remToNum(spacing.cell) / 2,
            }
          );

          return (
            <CellModel
              key={cell._key}
              animating={animating && visibleComplete}
              background={background}
              cell={cell}
              cellByPosition={cellByPosition}
              className="absolute"
              fill={animatingFillByKey[cell._key] ?? "unfilled"}
              highlighted={highlighted[cell.position[0]]?.[cell.position[1]]}
              immediate={immediate}
              muted={mutedProp}
              ready={visibleComplete}
              shadow={shadow[cell.position[0]]?.[cell.position[1]]}
              style={{
                top: `${coordinate.top}rem`,
                left: `${coordinate.left}rem`,
              }}
              variants={{
                hidden: {
                  transition: immediate
                    ? { duration: 0 }
                    : { delay: hiddenDelay[cell._key] },
                },
                visible: {
                  transition: immediate
                    ? { duration: 0 }
                    : { delay: visibleDelay[cell._key] },
                },
              }}
              getSunIndex={() =>
                Math.abs(
                  15 -
                    ((visuallyUnsatisfiedActiveNodes.filter(({ sink }) => !sink)
                      .length -
                      1) %
                      30)
                )
              }
              pickPlaySinkSatisfied={({
                playSinkSatisfied,
                playSinkSatisfiedEnd,
                playSinkSatisfiedEndFull,
              }) =>
                !doneAfterAnimating
                  ? playSinkSatisfied
                  : moves.length > bestScore
                  ? playSinkSatisfiedEnd
                  : playSinkSatisfiedEndFull
              }
              onAnimationComplete={(definition) => {
                if (
                  definition === (animatingFillByKey[cell._key] ?? "unfilled")
                ) {
                  setVisibleNodesByFill((visibleNodesByFill) => ({
                    ...visibleNodesByFill,
                    ...(!(cell._key in visibleFillByKey)
                      ? {}
                      : {
                          [visibleFillByKey[cell._key]!]: visibleNodesByFill[
                            visibleFillByKey[cell._key]!
                          ].difference(new Set([cell])),
                        }),
                    [definition as keyof typeof visibleNodesByFill]:
                      visibleNodesByFill[
                        definition as keyof typeof visibleNodesByFill
                      ].union(new Set([cell])),
                  }));
                } else if (
                  definition === "inactive" ||
                  definition === "disactive"
                ) {
                  setVisibleNodesByFill((visibleNodesByFill) => ({
                    fire: visibleNodesByFill.fire.difference(new Set([cell])),
                    sun: visibleNodesByFill.sun.difference(new Set([cell])),
                    unfilled: visibleNodesByFill.unfilled.difference(
                      new Set([cell])
                    ),
                    water: visibleNodesByFill.water.difference(new Set([cell])),
                  }));
                }
              }}
            />
          );
        })}
        {blocks.map((block) => {
          const active = consideredActiveByKey[block._key]!;
          const adjacentBlocks = consideredAdjacentBlocksByKey[block._key]!;

          const position =
            block === consideredBlock && consideredPosition
              ? consideredPosition
              : blockPositionByKey[block._key]!;

          const originalPositionCoordinate = positionToCoordinate(
            blockPositionByKey[block._key]!
          );

          const positionCoordinate =
            position === blockPositionByKey[block._key]!
              ? originalPositionCoordinate
              : positionToCoordinate(position);

          const resolvedCoordinate =
            controlledBlocks[block._key]?.coordinate ?? positionCoordinate;

          const coordinate = sumCoordinates(resolvedCoordinate, {
            top: -remToNum(spacing.block) / 2,
            left: -remToNum(spacing.block) / 2,
          });

          return (
            <BlockModel
              key={block._key}
              active={active}
              animating={animating && visibleComplete}
              block={block}
              fill={animatingFillByKey[block._key] ?? "unfilled"}
              // HACK Just for Moon
              fillReal={fillByKey[block._key] ?? "unfilled"}
              immediate={immediate}
              muted={mutedProp}
              nCount={visibleComplete ? adjacentBlocks.size : 0}
              ready={visibleComplete}
              shape={block.shape}
              className={`absolute drop-shadow-md ${
                !canInteract || !block.mobile
                  ? ""
                  : block !== consideredBlock
                  ? "cursor-grab hover:drop-shadow-lg focus:drop-shadow-lg"
                  : "cursor-grabbing drop-shadow-xl"
              } ${
                (animatingFillByKey[block._key] ?? "unfilled") !==
                (visibleFillByKey[block._key] ?? "unfilled")
                  ? "z-[70]"
                  : controlledBlocks[block._key]
                  ? "z-[60]"
                  : block === hoveredBlock && !consideredBlock
                  ? "z-50"
                  : block === consideredBlock
                  ? "z-40"
                  : block.mobile
                  ? "z-30"
                  : "z-20"
              } ${
                !visibleComplete || immediate
                  ? ""
                  : controlledBlocks[block._key]?.animationClassName ??
                    "transition-[top,left,transform]"
              }`}
              style={{
                top: `${coordinate.top}rem`,
                left: `${coordinate.left}rem`,
                rotate:
                  block !== consideredBlock
                    ? 0
                    : Math.sign(
                        resolvedCoordinate.left -
                          originalPositionCoordinate.left
                      ) *
                      Math.max(
                        0,
                        Math.log2(
                          Math.abs(
                            resolvedCoordinate.left -
                              originalPositionCoordinate.left
                          )
                        )
                      ),
              }}
              variants={{
                hidden: {
                  transition: immediate
                    ? { duration: 0 }
                    : { delay: hiddenDelay[block._key] },
                },
                visible: {
                  transition: immediate
                    ? { duration: 0 }
                    : { delay: visibleDelay[block._key] },
                },
              }}
              getSunIndex={() =>
                Math.abs(
                  15 -
                    ((visuallyUnsatisfiedActiveNodes.filter(({ sink }) => !sink)
                      .length -
                      1) %
                      30)
                )
              }
              pickPlaySinkSatisfied={({
                playSinkSatisfied,
                playSinkSatisfiedEnd,
                playSinkSatisfiedEndFull,
              }) =>
                !doneAfterAnimating
                  ? playSinkSatisfied
                  : moves.length > bestScore
                  ? playSinkSatisfiedEnd
                  : playSinkSatisfiedEndFull
              }
              onAnimationComplete={(definition) => {
                if (
                  definition === (animatingFillByKey[block._key] ?? "unfilled")
                ) {
                  setVisibleNodesByFill((visibleNodesByFill) => ({
                    ...visibleNodesByFill,
                    ...(!(block._key in visibleFillByKey)
                      ? {}
                      : {
                          [visibleFillByKey[block._key]!]: visibleNodesByFill[
                            visibleFillByKey[block._key]!
                          ].difference(new Set([block])),
                        }),
                    [definition as keyof typeof visibleNodesByFill]:
                      visibleNodesByFill[
                        definition as keyof typeof visibleNodesByFill
                      ].union(new Set([block])),
                  }));
                } else if (
                  definition === "inactive" ||
                  definition === "disactive"
                ) {
                  setVisibleNodesByFill((visibleNodesByFill) => ({
                    fire: visibleNodesByFill.fire.difference(new Set([block])),
                    sun: visibleNodesByFill.sun.difference(new Set([block])),
                    unfilled: visibleNodesByFill.unfilled.difference(
                      new Set([block])
                    ),
                    water: visibleNodesByFill.water.difference(
                      new Set([block])
                    ),
                  }));
                }
              }}
              {...(!canInteract || !block.mobile
                ? {}
                : {
                    ...blockPropsForKeyboard(block, { position }),
                    ...blockPropsForPointer(block, { position }),
                  })}
            />
          );
        })}
      </MotionDiv>
    </motion.div>
  );
};

export const Level = memo(LevelPure);
