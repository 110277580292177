import { addHours, subHours } from "date-fns/fp";
import { flow, isPlainObject as isPlainObjectRaw } from "lodash/fp";
import type { SetStateAction } from "react";
// @ts-expect-error -- EXPECTED The shims need it
import difference from "set.prototype.difference";
// @ts-expect-error -- EXPECTED The shims need it
import intersection from "set.prototype.intersection";
// @ts-expect-error -- EXPECTED The shims need it
import union from "set.prototype.union";

// TODO [engine:node@>=22.0.0]: Set methods
difference.shim();
intersection.shim();
union.shim();

export type Tuple = [any, ...any[]];

export const fp: {
  <A, R1, R2, R3, R4, R5, R6, R7>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6,
    f7: (a: R6) => R7
  ): R7;
  <A, R1, R2, R3, R4, R5, R6, R7>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6,
    f7: (a: R6) => R7,
    ...fns: ((a: any) => any)[]
  ): any;
  <A, R1, R2, R3, R4, R5, R6>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6
  ): R6;
  <A, R1, R2, R3, R4, R5>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5
  ): R5;
  <A, R1, R2, R3, R4>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4
  ): R4;
  <A, R1, R2, R3>(
    arg: A,
    f1: (arg: A) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3
  ): R3;
  <A, R1, R2>(arg: A, f1: (arg: A) => R1, f2: (a: R1) => R2): R2;
  <A, R1>(arg: A, f1: (arg: A) => R1): R1;
  <A>(arg: A, ...fns: ((a: any) => any)[]): any;
} = <A>(arg: A, ...fns: ((a: any) => any)[]) => flow(...fns)(arg);

const isPlainObject = (value: unknown): value is object =>
  isPlainObjectRaw(value);

const traverse =
  <Item, Collection>(
    transform: (collection: Collection) => {
      getAndRemoveOne: () => Item;
      // @ts-expect-error -- TODO IDK
      getCollection: () => collection;
      push: (...items: Item[]) => void;
      remove: (...items: Item[]) => void;
      size: () => number;
    }
  ) =>
  <Acc>(
    callback: (
      acc: Acc,
      value: Item,
      methods: {
        done: () => Item[];
        push: (...items: Item[]) => void;
        remove: (...items: Item[]) => void;
      }
    ) => Acc,
    initialAcc: Acc
  ) =>
  <Item, Collection>(collection: Collection) => {
    const { getAndRemoveOne, getCollection, push, remove, size } = transform<
      // @ts-expect-error -- TODO IDK
      Item,
      Collection
    >(collection);
    let acc = initialAcc;

    while (size()) {
      const value = getAndRemoveOne();
      let done = false;
      acc = callback(acc, value, {
        push,
        remove,
        done: () => {
          done = true;
          return getCollection();
        },
      });

      if (done) {
        return acc;
      }
    }

    return acc;
  };

export const queue = traverse<
  // @ts-expect-error -- TODO IDK
  Item,
  // @ts-expect-error -- TODO IDK
  Item[]
>((initial) => {
  const queue = [...initial];

  return {
    getAndRemoveOne: () => queue.shift()!,
    getCollection: () => queue,
    push: (...items) => queue.push(...items),
    size: () => queue.length,
    remove: (...items) =>
      items.forEach((item) => {
        const index = queue.indexOf(item);
        if (index !== -1) {
          queue.splice(index, 1);
        }
      }),
  };
});

export const queueSet = traverse<
  // @ts-expect-error -- TODO IDK
  Item,
  // @ts-expect-error -- TODO IDK
  Set<Item>
>((initial) => {
  const queue = new Set(initial);

  return {
    getAndRemoveOne: () => queue.values().next().value!,
    getCollection: () => queue,
    push: (...items) => items.forEach((item) => queue.add(item)),
    remove: (...items) => items.forEach((item) => queue.delete(item)),
    size: () => queue.size,
  };
});

export const actionify = <T>(action: SetStateAction<T>) =>
  action instanceof Function ? action : () => action;

export const dateToDateString = (date: Date) =>
  date.toISOString().split("T")[0]!;

export const dateStringToDate = (dateString: string) =>
  new Date(`${dateString}T00:00:00`);

export const minDate = () => subHours(12, new Date());

export const maxDate = () => addHours(14, new Date());

const hasEqualObjectValues = <A extends object, B extends object>(
  a: A,
  b: B
) => {
  const aKeys = Object.keys(a);

  return (
    aKeys.length === Object.keys(b).length &&
    aKeys.every(
      (key) =>
        (a[key as keyof typeof a] as any) === (b[key as keyof typeof b] as any)
    )
  );
};

export const reuseReferences = <T>(value: T) =>
  value instanceof Date
    ? (previous: T) =>
        previous instanceof Date && previous.getTime() === value.getTime()
          ? previous
          : value
    : Array.isArray(value)
    ? (previous: T) => {
        if (!Array.isArray(previous)) {
          return value;
        }

        const valueRecursive = value.map((value, index) =>
          reuseReferences(value)((previous as any)[index] as unknown)
        ) as typeof value;

        return valueRecursive.length === previous.length &&
          valueRecursive.every((elem, index) => elem === previous[index])
          ? previous
          : valueRecursive.length === value.length &&
            valueRecursive.every((elem, index) => elem === value[index])
          ? value
          : valueRecursive;
      }
    : isPlainObject(value)
    ? (previous: T) => {
        if (!isPlainObject(previous)) {
          return value;
        }

        const valueRecursive = Object.fromEntries(
          Object.entries(value).map(([key, value]) => [
            key,
            reuseReferences(value)((previous as any)[key] as any),
          ])
        ) as typeof value;

        return hasEqualObjectValues(valueRecursive, previous)
          ? previous
          : hasEqualObjectValues(valueRecursive, value)
          ? value
          : valueRecursive;
      }
    : () => value;
