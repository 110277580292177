import { motion } from "framer-motion";
import type { ComponentProps, HTMLAttributes } from "react";

export const Card = ({
  children,
  className,
  skeleton,
  ...props
}: ComponentProps<typeof motion.div> & {
  skeleton?: boolean;
}) => (
  <motion.div
    className={`flex shrink-0 flex-col items-center rounded-xl border border-slate-200 p-2 text-center shadow dark:border-slate-800 ${
      skeleton && "animate-pulse bg-zinc-950/10 dark:bg-zinc-50/10"
    } ${className}`}
    {...props}
  >
    {children}
  </motion.div>
);

export const CardText = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={`relative max-w-full shrink-0 overflow-hidden text-ellipsis text-nowrap ${className}`}
    {...props}
  />
);

export const CardGrid = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={`my-2 flex flex-wrap gap-4 ${className}`} {...props}>
    {children}
  </div>
);
