import type { ComponentProps } from "react";

export const Button = ({
  children,
  className,
  type = "button",
  "title": titleInitial,
  "aria-label": ariaLabelInitial,
  title = titleInitial ?? ariaLabelInitial,
  "aria-label": ariaLabel = ariaLabelInitial ?? titleInitial,
  ...props
}: ComponentProps<"button">) => (
  <button
    type={type}
    aria-label={ariaLabel}
    title={title}
    className={`outline-blue-500 hover:scale-110 focus:scale-110 disabled:hover:scale-100 disabled:focus:scale-100 ${className}`}
    {...props}
  >
    {children}
  </button>
);
