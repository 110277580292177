import Face2RoundedIcon from "@mui/icons-material/Face2Rounded";
import Face3RoundedIcon from "@mui/icons-material/Face3Rounded";
import Face4RoundedIcon from "@mui/icons-material/Face4Rounded";
import Face5RoundedIcon from "@mui/icons-material/Face5Rounded";
import Face6RoundedIcon from "@mui/icons-material/Face6Rounded";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";
import type { ObjectId } from "mongodb/lib/bson";
import { useEffect, useState } from "react";
import type { ComponentProps } from "react";

import type { MoveSet } from "@sunblocks/game";
import type { RouterOutputs } from "@sunblocks/trpc";

import { Avatar } from "../Avatar";
import { CarouselCounter } from "../CarouselCounter";
import { MenuDropdown } from "../MenuDropdown";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: { spacing },
} = tailwindConfig;

const controlRems = Number(spacing.control.split("rem")[0]!);

const Faces = [
  FaceRoundedIcon,
  Face2RoundedIcon,
  Face3RoundedIcon,
  Face4RoundedIcon,
  Face5RoundedIcon,
  Face6RoundedIcon,
];

const Avatars = ({
  users,
  active: activeProp,
}: {
  active: boolean;
  users: (ComponentProps<typeof Avatar>["user"] & { _id: ObjectId })[];
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const active = activeProp && users.length > 1;

  useEffect(() => {
    if (!active) {
      return () => {};
    }

    const interval = setInterval(
      () =>
        setActiveIndex(
          (activeIndex) => (activeIndex + 1) % Number.MAX_SAFE_INTEGER
        ),
      5000
    );

    return () => clearInterval(interval);
  }, [active]);

  return (
    <div className="relative size-control">
      {users.map((user, index) => (
        <Avatar
          className={`absolute left-0 top-0 drop-shadow-lg transition-opacity duration-500 ${
            activeIndex % users.length === index ? "opacity-100" : "opacity-0"
          }`}
          key={user._id.toString()}
          user={user}
        />
      ))}
    </div>
  );
};

export const LevelHeader = ({
  levelScores,
  moveSet,
  onOpenLevelScores,
  won,
  ...menuDropdownProps
}: ComponentProps<typeof MenuDropdown> & {
  levelScores?: RouterOutputs["scores"]["level"];
  moveSet: MoveSet;
  onOpenLevelScores?: () => void;
  won: boolean;
}) => (
  <>
    <div className="size-menu" />
    <CarouselCounter
      className="min-w-control text-3xl"
      maxValue={moveSet[0].length + moveSet[1].length}
      numberWidthPx={16 * controlRems}
      showingPerSide={2}
      value={moveSet[0].length}
    >
      {(index, active) => {
        const Face = Faces[index % Faces.length]!;

        return (
          <div
            className={`transition-opacity duration-1000 ${
              won && index !== moveSet[0].length ? "opacity-0" : "opacity-100"
            }`}
          >
            {levelScores?.scores && index in (levelScores?.scores ?? {}) ? (
              <Avatars
                active={active && !won}
                users={levelScores?.scores[index]!}
              />
            ) : index === levelScores?.bestScore ? (
              <Face
                className="size-control"
                style={{
                  height: spacing.control,
                  width: spacing.control,
                }}
              />
            ) : (
              index
            )}
          </div>
        );
      }}
    </CarouselCounter>
    <div
      className={`flex flex-shrink-0 transition-opacity duration-1000 ${
        won ? "opacity-0" : "opacity-100"
      }`}
    >
      <MenuDropdown
        {...menuDropdownProps}
        onOpenLevelScores={onOpenLevelScores}
      />
    </div>
  </>
);
