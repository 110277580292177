import type { Config } from "tailwindcss";

// eslint-disable-next-line import/no-relative-packages -- TODO IDK Why we can't import from @sunblocks/tailwind directly
import defaultConfig from "../tailwind/src";

const config = {
  ...defaultConfig,
  content: ["../*/src/**/*.{js,ts,jsx,tsx,mdx}"],
} satisfies Config;

export default config;
