import type { HTMLAttributes } from "react";

export const H1 = ({
  children,
  className,
  skeleton,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & {
  skeleton?: boolean;
}) => (
  <h1
    className={`mb-2 mt-4 text-xl font-semibold ${
      skeleton &&
      "h-7 w-1/3 animate-pulse rounded-md bg-zinc-950/10 dark:bg-zinc-50/10"
    } ${className}`}
    {...props}
  >
    {!skeleton && children}
  </h1>
);
