import { useCallback, useState } from "react";

export const useVibrate = <Fn extends (...any: any[]) => any>(
  fn: Fn,
  pattern: VibratePattern
): Fn => {
  const [fixedPattern] = useState(() => pattern);

  return useCallback(
    (...args: Parameters<Fn>) => {
      (
        globalThis.navigator.vibrate as
          | typeof globalThis.navigator.vibrate
          // HACK Safari does not have support for vibrate https://stackoverflow.com/a/56926744/4806413
          | undefined
      )?.(fixedPattern);
      return fn(...args) as ReturnType<Fn>;
    },
    [fixedPattern, fn]
  ) as Fn;
};
