import type { ComponentProps } from "react";

import { MenuDropdown } from "../MenuDropdown";

export const PageHeader = ({
  ...menuDropdownProps
}: ComponentProps<typeof MenuDropdown>) => (
  <>
    <div className="fixed left-0 right-0 top-0 z-20 flex h-menu items-center bg-white shadow dark:bg-zinc-950">
      <div className="grow" />
      <MenuDropdown {...menuDropdownProps} />
    </div>
    <div className="h-menu" />
  </>
);
