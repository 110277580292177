import RedoRounded from "@mui/icons-material/RedoRounded";
import ReplayRounded from "@mui/icons-material/ReplayRounded";
import UndoRounded from "@mui/icons-material/UndoRounded";
import { useCallback, useEffect } from "react";
import type { SetStateAction } from "react";

import type { MoveSet } from "@sunblocks/game";

import { Button } from "../Button";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: { spacing },
} = tailwindConfig;

export const HistoryButtons = ({
  moveSet,
  setMoveSet,
}: {
  moveSet: MoveSet;
  setMoveSet: (
    moveSet: SetStateAction<MoveSet>,
    historyRewrite?: boolean
  ) => void;
}) => {
  const rewriteHistory = useCallback(
    (numForward: number) =>
      !numForward
        ? undefined
        : setMoveSet(
            ([moves, redoMoves]) =>
              numForward < 0
                ? [
                    moves.slice(0, numForward),
                    [...moves.slice(numForward), ...redoMoves],
                  ]
                : [
                    [...moves, ...redoMoves.slice(0, numForward)],
                    redoMoves.slice(numForward),
                  ],
            true
          ),
    [setMoveSet]
  );

  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      const { ctrlKey, key, metaKey, shiftKey } = event;
      if ((!metaKey && !ctrlKey) || (key !== "y" && key !== "z")) {
        return;
      }

      event.preventDefault();

      ({
        false: {
          y: () => rewriteHistory(1),
          z: () => rewriteHistory(-1),
        },
        true: {
          z: () => rewriteHistory(1),
        },
      })[`${shiftKey}`][key]?.();
    };

    document.addEventListener("keydown", callback);
    return () => document.removeEventListener("keydown", callback);
  }, [rewriteHistory]);

  return (
    <>
      <Button
        className="size-menu -rotate-90 transition-colors hover:text-gray-50 focus:text-gray-50 disabled:text-gray-800"
        disabled={!moveSet[0].length}
        title="Undo All"
        onClick={() => rewriteHistory(-moveSet[0].length)}
      >
        <ReplayRounded
          style={{
            height: spacing.control,
            width: spacing.control,
          }}
        />
      </Button>
      <Button
        className="size-menu transition-colors hover:text-gray-50 focus:text-gray-50 disabled:text-gray-800"
        disabled={!moveSet[0].length}
        title="Undo"
        onClick={() => rewriteHistory(-1)}
      >
        <UndoRounded
          style={{
            height: spacing.control,
            width: spacing.control,
          }}
        />
      </Button>
      <Button
        className="size-menu transition-colors hover:text-gray-50 focus:text-gray-50 disabled:text-gray-800"
        disabled={!moveSet[1].length}
        title="Redo"
        onClick={() => rewriteHistory(1)}
      >
        <RedoRounded
          style={{
            height: spacing.control,
            width: spacing.control,
          }}
        />
      </Button>
      <Button
        className="size-menu rotate-90 -scale-x-100 transition-colors hover:-scale-x-110 hover:scale-y-110 hover:text-gray-50 focus:-scale-x-110 focus:scale-y-110 focus:text-gray-50 disabled:text-gray-800 disabled:hover:-scale-x-100 disabled:hover:scale-y-100 disabled:focus:-scale-x-100 disabled:focus:scale-y-100"
        disabled={!moveSet[1].length}
        title="Redo All"
        onClick={() => rewriteHistory(moveSet[1].length)}
      >
        <ReplayRounded
          style={{
            height: spacing.control,
            width: spacing.control,
          }}
        />
      </Button>
    </>
  );
};
