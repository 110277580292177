import WaterDropRounded from "@mui/icons-material/WaterDropRounded";
import type { ComponentProps } from "react";

import { MotionDiv } from "../Motion";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: {
    spacing,
    colors: { water },
  },
} = tailwindConfig;

// If each of the fills don't have a slightly different scale, then they won't re-animate when overriding one another
export const waterScale = 1.100003;

export const Water = ({
  className,
  variants,
  ...props
}: ComponentProps<typeof MotionDiv>) => (
  <MotionDiv
    {...props}
    className={`pointer-events-none size-blockContent ${className}`}
    variants={{
      inactive: { color: water.inactive },
      disactive: { color: water.disactive },
      unfilled: { color: water.unfilled },
      sun: { color: water.sun },
      fire: { color: water.fire },
      water: { color: water.water },
      ...variants,
    }}
  >
    <WaterDropRounded
      style={{
        height: spacing.blockContent,
        width: spacing.blockContent,
      }}
    />
  </MotionDiv>
);
