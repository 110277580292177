import Brightness7Rounded from "@mui/icons-material/Brightness7Rounded";
import type { ComponentProps } from "react";

import { MotionDiv } from "../Motion";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: {
    spacing,
    colors: { sun },
  },
} = tailwindConfig;

// If each of the fills don't have a slightly different scale, then they won't re-animate when overriding one another
export const sunScale = 1.100001;

export const Sun = ({
  className,
  colors = sun,
  ...props
}: ComponentProps<typeof MotionDiv> & {
  colors?: { [key in keyof typeof sun]: string };
}) => (
  <MotionDiv
    {...props}
    className={`pointer-events-none size-blockContent ${className}`}
    variants={{
      inactive: { opacity: 0.5, color: colors.inactive, rotate: "0deg" },
      disactive: { opacity: 1, color: colors.disactive, rotate: "0deg" },
      unfilled: { opacity: 1, color: colors.unfilled, rotate: "0deg" },
      fire: { opacity: 1, color: colors.fire, rotate: "0deg" },
      water: { opacity: 1, color: colors.water, rotate: "0deg" },
      sun: { opacity: 1, color: colors.sun },
      animating: {
        rotate: "360deg",
        transition: {
          rotate: {
            duration: 10,
            repeat: Infinity,
            ease: "linear",
          },
        },
      },
    }}
  >
    <Brightness7Rounded
      style={{
        height: spacing.blockContent,
        width: spacing.blockContent,
      }}
    />
  </MotionDiv>
);
