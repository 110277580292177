import { MotionContext, motion } from "framer-motion";
import {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { ComponentProps, ForwardedRef } from "react";

const MotionDivWithoutRef = (
  {
    onAnimationComplete,
    onAnimationStart,
    onAnimationStartDelayed,
    variants,
    animate: animateProp,
    ...props
  }: Omit<
    ComponentProps<typeof motion.div>,
    "onAnimationComplete" | "onAnimationStart"
  > & {
    onAnimationComplete?:
      | ComponentProps<typeof motion.div>["onAnimationStart"]
      | {
          [definition: string]: ComponentProps<
            typeof motion.div
          >["onAnimationStart"];
        };
    onAnimationStart?:
      | ComponentProps<typeof motion.div>["onAnimationStart"]
      | {
          [definition: string]: ComponentProps<
            typeof motion.div
          >["onAnimationStart"];
        };
    onAnimationStartDelayed?:
      | ComponentProps<typeof motion.div>["onAnimationStart"]
      | {
          [definition: string]: ComponentProps<
            typeof motion.div
          >["onAnimationStart"];
        };
  },
  ref: ForwardedRef<HTMLDivElement>
) => {
  // https://github.com/framer/motion/issues/2765
  const delayedAnimations = useRef(new Set<NodeJS.Timeout>());
  useEffect(() => () => delayedAnimations.current.forEach(clearTimeout), []);

  const [delayedAnimationStarts, setDelayedAnimationStarts] = useState(
    new Set<string>()
  );

  useEffect(() => {
    delayedAnimationStarts.forEach((definition) => {
      if (typeof onAnimationStartDelayed === "function") {
        onAnimationStartDelayed?.(definition);
      } else {
        onAnimationStartDelayed?.[definition]?.(definition);
      }
    });

    setDelayedAnimationStarts((fireAnimationStart) =>
      !fireAnimationStart.size ? fireAnimationStart : new Set()
    );
  }, [delayedAnimationStarts, onAnimationStartDelayed]);

  const { animate: animateContext } = useContext(MotionContext);

  const animate = useMemo(() => {
    const animate = animateProp ?? animateContext;

    return Array.isArray(animate)
      ? animate
      : typeof animate === "string"
      ? [animate]
      : [];
  }, [animateContext, animateProp]);

  const [previousAnimate, setPreviousAnimate] = useState<string[]>([]);
  useEffect(() => {
    setPreviousAnimate((previousAnimate) =>
      previousAnimate.length === animate.length &&
      previousAnimate.every(
        (definition, index) => definition === animate[index]
      )
        ? previousAnimate
        : animate
    );
  }, [animate]);

  const nextAnimate = useMemo(
    () => animate.filter((definition) => !previousAnimate.includes(definition)),
    [animate, previousAnimate]
  );

  return (
    <motion.div
      ref={ref}
      {...props}
      {...(!animateProp ? {} : { animate: animateProp })}
      variants={variants}
      onAnimationComplete={
        onAnimationComplete &&
        ((definition) => {
          const definitions = (
            Array.isArray(definition) ? definition : [definition]
          ).filter(
            // TODO [typescript@>=5.5.4]: Type coercion is not necessary
            (definition): definition is string => typeof definition === "string"
          );

          if (typeof onAnimationComplete === "function") {
            onAnimationComplete(definition);
          } else {
            definitions.forEach((definition) => {
              onAnimationComplete[definition]?.(definition);
            });
          }
        })
      }
      onAnimationStart={
        (onAnimationStart || onAnimationStartDelayed) &&
        ((definition) => {
          const definitions = (
            Array.isArray(definition) ? definition : [definition]
          )
            .filter(
              // TODO [typescript@>=5.5.4]: Type coercion is not necessary
              (definition): definition is string =>
                typeof definition === "string"
            )
            .filter((definition) => nextAnimate.includes(definition));

          if (typeof onAnimationStart === "function") {
            onAnimationStart?.(definition);
          } else {
            definitions.forEach((definition) => {
              onAnimationStart?.[definition]?.(definition);
            });
          }

          definitions.forEach((definition) => {
            const variant = variants?.[definition];
            if (
              // TODO
              typeof variant === "function" ||
              !variant?.transition?.delay
            ) {
              if (typeof onAnimationStartDelayed === "function") {
                onAnimationStartDelayed?.(definition);
              } else {
                onAnimationStartDelayed?.[definition]?.(definition);
              }

              return;
            }

            delayedAnimations.current.add(
              setTimeout(
                () =>
                  setDelayedAnimationStarts((fireAnimationStart) =>
                    fireAnimationStart.union(new Set([definition]))
                  ),
                (variant.transition?.delay ?? 0) * 1000
              )
            );
          });
        })
      }
    />
  );
};

export const MotionDiv = forwardRef(MotionDivWithoutRef);
