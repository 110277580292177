import { motion } from "framer-motion";
import type { ComponentProps, ReactNode } from "react";

import { Card } from "../Card";
import { Fire } from "../Fire";
import { Flower } from "../Flower";
import { Moon } from "../Moon";
import { tailwindConfig } from "../tailwind-config";
import { useLevelTheme } from "../use-level-theme";
import { useMatchMedia } from "../use-match-media";

const {
  theme: {
    colors: {
      block: { inner: blockInner, light: blockColors, night: nightBlockColors },
      cell: cellColorsUnscoped,
    },
  },
} = tailwindConfig;

export const LevelCard = ({
  animating,
  children,
  className,
  elements,
  finished,
  onAnimationComplete,
  variants,
  onFinishedAnimationComplete = () => {},
  ...props
}: ComponentProps<typeof Card> & {
  animating?: boolean;
  children?: ReactNode;
  elements?: Parameters<typeof useLevelTheme>[0];
  finished?: boolean;
  onFinishedAnimationComplete?: () => void;
}) => {
  const levelTheme = useLevelTheme(elements);

  const unfinishedVariant =
    levelTheme === "night"
      ? "sun"
      : levelTheme === "fire"
      ? "fire"
      : "unfilled";

  const finishedVariant =
    levelTheme === "night"
      ? "unfilled"
      : levelTheme === "fire"
      ? "water"
      : "sun";

  const colorScheme = useMatchMedia("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";

  const { [colorScheme]: cellColors } = cellColorsUnscoped;

  return (
    <Card
      {...props}
      className={`relative ${
        levelTheme === "night" ? "text-slate-200" : "text-slate-950"
      } ${className}`}
      initial={!finished || animating ? unfinishedVariant : finishedVariant}
      animate={!finished ? unfinishedVariant : [finishedVariant, "animating"]}
      variants={{
        ...variants,
        fire: {
          backgroundColor: blockColors.normal.fire,
          transition: {
            duration: animating ? 0.35 : 0,
          },
        },
        water: {
          backgroundColor: blockColors.normal.water,
          transition: {
            duration: animating ? 0.35 : 0,
          },
        },
        unfilled: {
          backgroundColor: {
            // fire shouldn't happen
            fire: "",
            default: blockColors.normal.unfilled,
            disactive: blockColors.weak.unfilled,
            ground: cellColors.back,
            inert: blockColors.normal.inactive,
            n: blockColors.n.inactive,
            night: nightBlockColors.normal.unfilled,
            weak: blockColors.weak.unfilled,
          }[levelTheme],
          transition: {
            duration: animating ? 0.35 : 0,
          },
        },
        sun: {
          backgroundColor: {
            // fire shouldn't happen
            fire: "",
            default: blockColors.normal.sun,
            disactive: blockColors.weak.unfilled,
            ground: cellColors.back,
            inert: blockColors.normal.inactive,
            n: blockColors.normal.sun,
            night: nightBlockColors.normal.unfilled,
            weak: blockColors.weak.unfilled,
          }[levelTheme],
          transition: {
            duration: animating ? 0.35 : 0,
          },
        },
      }}
      onAnimationComplete={(definition) => {
        onAnimationComplete?.(definition);
        if (definition !== finishedVariant || !animating || !finished) {
          return;
        }

        onFinishedAnimationComplete?.();
      }}
    >
      {(levelTheme === "disactive" ||
        levelTheme === "ground" ||
        levelTheme === "inert" ||
        levelTheme === "weak") && (
        <motion.div
          className={`absolute inset-0 min-h-8 min-w-8 ${
            {
              disactive: "m-auto size-[calc(100%-4rem)] rounded-xl",
              ground: "m-auto size-[calc(100%-5rem)] rounded-3xl opacity-80",
              inert: "m-auto size-[calc(100%-1rem)] rounded-lg",
              weak: "m-auto size-[calc(100%-4rem)] rounded-xl",
            }[levelTheme]
          }`}
          variants={{
            unfilled: {
              backgroundColor: {
                disactive: blockInner.weak.disactive,
                ground: cellColors.inner.unfilled,
                inert: blockInner.inert,
                weak: blockInner.weak.inactive,
              }[levelTheme],
              transition: {
                duration: animating ? 0.35 : 0,
              },
            },
            sun: {
              backgroundColor: {
                disactive: blockInner.weak.sun,
                ground: cellColors.inner.sun,
                inert: blockColors.normal.sun,
                weak: blockInner.weak.sun,
              }[levelTheme],
              transition: {
                duration: animating ? 0.35 : 0,
              },
            },
          }}
        />
      )}
      {!finished ? null : levelTheme === "night" ? (
        <Moon immediate={!animating} />
      ) : levelTheme === "fire" ? (
        <Fire immediate={!animating} />
      ) : (
        <Flower immediate={!animating} />
      )}
      {children}
    </Card>
  );
};
