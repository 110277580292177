import LocalFloristRounded from "@mui/icons-material/LocalFloristRounded";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";

import { MotionDiv } from "../Motion";
import { Sprinkle } from "../Sprinkle";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: {
    spacing,
    colors: { flower },
  },
} = tailwindConfig;

export const Flower = ({
  className,
  variants,
  colors = flower,
  immediate = false,
  ...props
}: ComponentProps<typeof MotionDiv> & {
  colors?: {
    [key in keyof typeof flower]: {
      [key2 in keyof (typeof flower)[key]]: string;
    };
  };
  immediate?: boolean;
}) => (
  <motion.div
    className={`pointer-events-none relative size-blockContent ${className}`}
  >
    {!immediate && <Sprinkle className="absolute top-0" variantName="sun" />}
    <MotionDiv
      {...props}
      className="absolute top-0 size-blockContent"
      variants={{
        inactive: { scale: 1 },
        disactive: { scale: 1 },
        unfilled: { scale: 1 },
        fire: { scale: 1 },
        water: { scale: 1 },
        sun: {
          scale: [1, 2, 1.8, 1],
          transition: immediate
            ? { delay: 0, duration: 0 }
            : { delay: 0.15, duration: 0.4, bounce: true },
        },
        ...variants,
      }}
    >
      <motion.div
        className="absolute top-0 h-16 overflow-hidden"
        style={{
          clipPath: `circle(1.9rem at 50% 50%)`,
        }}
        variants={{
          inactive: {
            opacity: 0.5,
            color: colors.petals.inactive,
            rotate: "0deg",
          },
          disactive: {
            opacity: 0.75,
            color: colors.petals.disactive,
            rotate: "0deg",
          },
          unfilled: {
            opacity: 1,
            color: colors.petals.unfilled,
            rotate: "0deg",
          },
          fire: {
            opacity: 1,
            color: colors.petals.fire,
            rotate: "0deg",
          },
          water: {
            opacity: 1,
            color: colors.petals.water,
            rotate: "0deg",
          },
          sun: {
            opacity: 1,
            color: colors.petals.sun,
            transition: { delay: immediate ? 0 : 0.15, duration: 0 },
          },
          animating: {
            rotate: "360deg",
            transition: {
              delay: immediate ? 0 : 0.75,
              duration: 10,
              repeat: Infinity,
              ease: "linear",
            },
          },
        }}
      >
        <LocalFloristRounded
          style={{
            height: spacing.blockContent,
            width: spacing.blockContent,
          }}
        />
      </motion.div>
      <motion.div
        className="absolute bottom-0 h-11 overflow-hidden"
        style={{
          clipPath: `polygon(0% 0%, 35% 0, 50% 50%, 65% 0, 100% 0%, 100% 100%, 0% 100%)`,
        }}
        variants={{
          inactive: { opacity: 0.5, color: colors.leaves.inactive },
          disactive: { opacity: 0.75, color: colors.leaves.disactive },
          unfilled: { opacity: 1, color: colors.leaves.unfilled },
          fire: { opacity: 1, color: colors.leaves.fire },
          water: { opacity: 1, color: colors.leaves.water },
          sun: {
            opacity: 1,
            color: colors.leaves.sun,
            transition: { delay: immediate ? 0 : 0.15, duration: 0 },
          },
        }}
      >
        <LocalFloristRounded
          className="-translate-y-[3.25rem]"
          style={{
            height: spacing.blockContent,
            width: spacing.blockContent,
          }}
        />
      </motion.div>
    </MotionDiv>
  </motion.div>
);
