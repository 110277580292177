import { z } from "zod";

import { createStorage } from "./use-storage";

export const {
  StorageProvider: SessionStorage,
  useScopedStorage: useScopedSessionStorage,
} = createStorage(
  () => sessionStorage,
  "sunblocks",
  z
    .object({
      firstTimeWin: z.record(z.string(), z.literal(true)),
    })
    .partial()
);
