import { motion } from "framer-motion";
import { mapValues } from "lodash/fp";
import Head from "next/head";
import { useState } from "react";
import type { ComponentProps } from "react";

import { tailwindConfig } from "../tailwind-config";
import { useMatchMedia } from "../use-match-media";
import { useWindowDimensions } from "../use-window-dimensions";

const {
  theme: {
    colors: {
      black,
      blue,
      brown,
      gray,
      orange,
      pink,
      purple,
      red,
      sky,
      stone,
      teal,
    },
  },
} = tailwindConfig;

// TODO Add <meta name="apple-mobile-web-app-status-bar-style" content="black">
// https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/MetaTags.html?ref=frontendchecklist
const backgrounds = {
  default: {
    light: {
      gradientFrom: sky[50],
      gradientTo: sky[200],
      themeColor: sky[100],
    },
    dark: {
      gradientFrom: sky[900],
      gradientTo: sky[950],
      themeColor: sky[900],
    },
  },
  inert: {
    light: {
      gradientFrom: stone[50],
      gradientTo: stone[400],
      themeColor: stone[400],
    },
    dark: {
      gradientFrom: stone[700],
      gradientTo: stone[800],
      themeColor: stone[800],
    },
  },
  ground: {
    light: {
      gradientFrom: brown[50],
      gradientTo: brown[400],
      themeColor: brown[400],
    },
    dark: {
      gradientFrom: orange[900],
      gradientTo: brown[900],
      themeColor: brown[900],
    },
  },
  n: {
    light: {
      gradientFrom: sky[50],
      gradientTo: teal[200],
      themeColor: teal[200],
    },
    dark: {
      gradientFrom: sky[900],
      gradientTo: teal[900],
      themeColor: teal[900],
    },
  },
  weak: {
    light: {
      gradientFrom: pink[100],
      gradientTo: purple[300],
      themeColor: pink[200],
    },
    dark: {
      gradientFrom: pink[950],
      gradientTo: purple[950],
      themeColor: pink[950],
    },
  },
  disactive: {
    light: {
      gradientFrom: pink[100],
      gradientTo: red[400],
      themeColor: red[200],
    },
    dark: {
      gradientFrom: pink[900],
      gradientTo: red[950],
      themeColor: red[950],
    },
  },
  fire: {
    light: {
      gradientFrom: blue[400],
      gradientTo: orange[400],
      themeColor: orange[400],
    },
    dark: {
      gradientFrom: blue[950],
      gradientTo: orange[900],
      themeColor: orange[900],
    },
  },
  night: {
    light: {
      gradientFrom: gray[900],
      gradientTo: black,
      themeColor: gray[900],
    },
    dark: {
      gradientFrom: gray[900],
      gradientTo: black,
      themeColor: gray[900],
    },
  },
};

export type BackgroundType = keyof typeof backgrounds;

const backgroundVariants = mapValues(
  (colorScheme: "dark" | "light") =>
    mapValues(
      ({
        [colorScheme]: {
          gradientFrom = backgrounds.default[colorScheme].gradientFrom,
          gradientTo = backgrounds.default[colorScheme].gradientTo,
        },
      }) => ({
        backgroundImage: `linear-gradient(to bottom right, ${gradientFrom}, ${gradientTo})`,
        transition: {
          duration: 3,
        },
      }),
      backgrounds
    ),
  { light: "light", dark: "dark" }
);

export const Background = ({
  children,
  className,
  background = "default",
  ...props
}: ComponentProps<typeof motion.div> & {
  background?: BackgroundType;
}) => {
  const prefersDark = useMatchMedia("(prefers-color-scheme: dark)");

  const colorScheme = prefersDark ? "dark" : "light";
  const { height } = useWindowDimensions();
  const [themeColor, setThemeColor] = useState<string>(
    backgrounds.default[colorScheme].themeColor
  );

  const [firstBackground] = useState(background);

  return (
    <>
      {/* TODO Don't use the VDOM to transition this, useAnimate or something is more fitting */}
      <motion.div
        className="hidden"
        initial={{
          backgroundColor: backgrounds[firstBackground][colorScheme].themeColor,
        }}
        animate={{
          backgroundColor: backgrounds[background][colorScheme].themeColor,
          transition: {
            duration: 3,
          },
        }}
        onUpdate={({ backgroundColor }) =>
          setThemeColor(backgroundColor as string)
        }
      />
      <Head>
        {[
          "msapplication-TileColor",
          "theme-color",
          "msapplication-navbutton-color",
          "apple-mobile-web-app-status-bar-style",
        ].map((name) => (
          <meta key={name} name={name} content={themeColor} />
        ))}
      </Head>
      <motion.div
        {...props}
        className={`fixed flex h-screen w-screen touch-none select-none flex-col items-center justify-center ${className}`}
        style={{
          height,
          willChange: "background-image",
        }}
        initial={firstBackground}
        animate={background}
        variants={backgroundVariants[colorScheme]}
      >
        {children}
      </motion.div>
    </>
  );
};
