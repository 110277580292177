import { motion } from "framer-motion";
import { useCallback, useMemo } from "react";
import type { ComponentProps } from "react";

import type {
  Active,
  Cell,
  CellModel as CellModelType,
  Fill,
  Position,
  getCellByPosition,
} from "@sunblocks/game";

import type { BackgroundType } from "../Background";
import { Flower } from "../Flower";
import { MotionDiv } from "../Motion";
import { Sun } from "../Sun";
import { tailwindConfig } from "../tailwind-config";
import { howlerOptions, useHowler, useHowler16 } from "../use-howler";
import { useMatchMedia } from "../use-match-media";
import { useVibrate } from "../use-vibrate";

const {
  theme: {
    colors: { cell: cellColors },
  },
} = tailwindConfig;

export const CellModel = ({
  cell,
  className,
  // TODO Better name
  getSunIndex,
  highlighted,
  onAnimationComplete,
  shadow,
  animating = true,
  background = "default",
  cellByPosition = {},
  variants = {},
  fill = cell.source ?? "unfilled",
  immediate = false,
  muted = false,
  pickPlaySinkSatisfied = ({ playSinkSatisfied }) => playSinkSatisfied,
  ready = true,
  active: activeProp = cell.active,
  cell: { sink, source, active: activeInitial = "inactive" },
  ...props
}: Omit<ComponentProps<typeof MotionDiv>, "animate" | "initial"> & {
  active?: CellModelType["active"];
  animate?: MaybeArray<string>;
  animating?: boolean;
  background?: BackgroundType;
  cell: Cell | Omit<CellModelType, "cell">;
  cellByPosition?: ReturnType<typeof getCellByPosition>;
  fill?: Fill;
  getSunIndex?: () => number;
  highlighted?: boolean;
  immediate?: boolean;
  initial?: MaybeArray<string>;
  muted?: boolean;
  neighbors?: {
    down?: Pick<CellModelType, "active">;
    left?: Pick<CellModelType, "active">;
    right?: Pick<CellModelType, "active">;
    up?: Pick<CellModelType, "active">;
  };
  pickPlaySinkSatisfied?: (sounds: {
    playSinkSatisfied: () => void;
    playSinkSatisfiedEnd: () => void;
    playSinkSatisfiedEndFull: () => void;
  }) => () => void;
  ready?: boolean;
  shadow?: boolean;
}) => {
  const { active = activeInitial } = useMemo(
    () => ({
      ...cell,
      active: activeProp,
    }),
    [activeProp, cell]
  );

  const playCellMove = useVibrate(
    useHowler({ preload: !muted, ...howlerOptions.cellMove })[0],
    50
  );
  const playNodeFire = useVibrate(
    useHowler({
      // TODO We don't know if this will ever be fire unless we're passed that boolean from Level
      preload: !muted && active === "active",
      ...howlerOptions.nodeFire,
    })[0],
    50
  );
  const playNodeWater = useVibrate(
    useHowler({
      // TODO We don't know if this will ever be water unless we're passed that boolean from Level
      preload: !muted && active === "active",
      ...howlerOptions.nodeWater,
    })[0],
    50
  );
  const playSinkSatisfied = useVibrate(
    useHowler({
      preload: !muted && Boolean(sink),
      ...howlerOptions.sinkSatisfied,
    })[0],
    50
  );
  const playSinkSatisfiedEnd = useVibrate(
    useHowler({
      preload: !muted && Boolean(sink),
      ...howlerOptions.sinkSatisfiedEnd,
    })[0],
    200
  );
  const playSinkSatisfiedEndFull = useVibrate(
    useHowler({
      preload: !muted && Boolean(sink),
      ...howlerOptions.sinkSatisfiedEndFull,
    })[0],
    [200, 100, 200]
  );
  const [playCellSunRaw] = useHowler16({
    preload: !muted && active === "active",
    ...howlerOptions.cellSun,
  });
  const playCellSun = useVibrate(
    useCallback(
      () =>
        playCellSunRaw[
          Math.max(0, Math.min(15, Math.round(getSunIndex?.() ?? 0)))
        ]?.(),
      [getSunIndex, playCellSunRaw]
    ),
    50
  );

  const animate = useMemo(
    () =>
      [
        shadow ? "shadow" : highlighted ? "highlighted" : "empty",
        active !== "disactive" && active,
        active === "active" && fill,
        active === "active" &&
          (fill === sink || fill === source) &&
          animating &&
          "animating",
        active === "disactive" && "disactive",
      ].filter(Boolean),
    [active, animating, fill, highlighted, shadow, sink, source]
  );
  const initial = useMemo(
    () =>
      immediate
        ? animate
        : [
            "empty",
            activeInitial !== "disactive" && activeInitial,
            activeInitial === "active" && (source ?? "unfilled"),
            activeInitial === "disactive" && "disactive",
          ].filter(Boolean),
    [activeInitial, animate, immediate, source]
  );

  const colorScheme = useMatchMedia("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";

  const {
    [background === "night" || colorScheme === "dark" ? "dark" : "light"]:
      colors,
  } = cellColors;

  return (
    <MotionDiv
      {...props}
      className={`size-fit ${className}`}
      variants={{
        ...variants,
        hidden: {
          opacity: 0,
          scale: 0,
          ...variants?.hidden,
        },
        visible: {
          opacity: 1,
          scale: 1,
          ...variants?.visible,
        },
      }}
      onAnimationStartDelayed={{
        visible: () => playCellMove(),
        hidden: () => playCellMove(),
      }}
    >
      <MotionDiv
        className={`flex size-cell items-center justify-center rounded-md ${
          background === "night"
            ? "bg-cell-night-back"
            : // TODO Use "dark:" prefix respecting OverrideMatchMedia, probably need storybook to natively override tailwind or something
            colorScheme === "dark"
            ? "bg-cell-dark-back"
            : "bg-cell-light-back"
        }`}
        initial={immediate && ready ? animate : initial}
        animate={!ready ? [] : animate}
        onAnimationComplete={onAnimationComplete}
        onAnimationStart={
          !ready
            ? undefined
            : fill === sink || fill === source
            ? {}
            : {
                sun: () => playCellSun(),
                fire: () => playNodeFire(),
                water: () => playNodeWater(),
              }
        }
      >
        <motion.div
          className={`pointer-events-none absolute z-20 size-block rounded-xl ${
            background === "night"
              ? "bg-cell-night-shadow"
              : // TODO Use "dark:" prefix respecting OverrideMatchMedia, probably need storybook to natively override tailwind or something
              colorScheme === "dark"
              ? "bg-cell-dark-shadow"
              : "bg-cell-light-shadow"
          }`}
          variants={{
            empty: { opacity: 0 },
            highlighted: { opacity: 0.25 },
            shadow: { opacity: 0.75 },
          }}
        />
        {activeInitial !== "inactive" && (
          <>
            <div className="absolute">
              <div className="pointer-events-none absolute z-[80] opacity-65">
                {"position" in cell &&
                  (
                    [
                      [0, 0],
                      [-1, 0],
                      [1, 0],
                      [0, -1],
                      [0, 1],
                    ] satisfies Position[]
                  )
                    .filter(
                      ([y, x]) =>
                        (y === 0 && x === 0) ||
                        (
                          ["active", "disactive"] as (Active | undefined)[]
                        ).includes(
                          cellByPosition[cell.position[0] + y]?.[
                            cell.position[1] + x
                          ]?.active
                        )
                    )
                    .map(([y, x]) => (
                      <motion.div
                        key={`${y}/${x}`}
                        className={`absolute size-blockInnerContent ${
                          y !== 0 || x !== 0 ? "" : "rounded-3xl"
                        }`}
                        style={{
                          translateY: `${y * 25 - 50}%`,
                          translateX: `${x * 25 - 50}%`,
                        }}
                        variants={{
                          disactive: {
                            backgroundColor: colors.inner.disactive,
                          },
                          unfilled: { backgroundColor: colors.inner.unfilled },
                          sun: {
                            backgroundColor: colors.inner.sun,
                            transition: { duration: 0.35 },
                          },
                          fire: {
                            backgroundColor: colors.inner.fire,
                            transition: { duration: 0.4 },
                          },
                          water: {
                            backgroundColor: colors.inner.water,
                            transition: { duration: 0.6 },
                          },
                        }}
                      />
                    ))}
              </div>
            </div>
            <motion.div className="absolute">
              {source === "sun" && (
                <Sun
                  className="absolute z-[80]"
                  colors={cellColors.sun}
                  style={{
                    translateY: "-50%",
                    translateX: "-50%",
                  }}
                />
              )}
              {sink === "sun" && (
                <Flower
                  className="z-[80]"
                  immediate={immediate}
                  colors={cellColors.flower}
                  onAnimationStart={
                    !ready
                      ? undefined
                      : {
                          sun: pickPlaySinkSatisfied({
                            playSinkSatisfied,
                            playSinkSatisfiedEnd,
                            playSinkSatisfiedEndFull,
                          }),
                        }
                  }
                />
              )}
            </motion.div>
          </>
        )}
      </MotionDiv>
    </MotionDiv>
  );
};
