import { keyBy, mapValues } from "lodash/fp";
import { useMemo } from "react";

import type { SanityValues } from "@sunblocks/sanity";
import { fp } from "@sunblocks/utils";

export const useLevelTheme = (
  elements: SanityValues["level"]["elements"] = []
) =>
  useMemo(() => {
    const elementMap = fp(
      elements,
      keyBy((e) => e),
      mapValues(() => true)
    ) as { [key in (typeof elements)[number]]?: true };

    return elementMap.moon
      ? "night"
      : elementMap.fire || elementMap.water
      ? "fire"
      : elementMap.disactive
      ? "disactive"
      : elementMap.weak
      ? "weak"
      : elementMap.n
      ? "n"
      : elementMap.ground
      ? "ground"
      : elementMap.inactive
      ? "inert"
      : "default";
  }, [elements]);
