import { useSyncExternalStore } from "react";

export const useDocumentVisibility = () =>
  useSyncExternalStore(
    (callback) => {
      document.addEventListener("blur", callback);
      document.addEventListener("focus", callback);
      document.addEventListener("focusin", callback);
      document.addEventListener("focusout", callback);
      document.addEventListener("mozvisibilitychange", callback);
      document.addEventListener("msvisibilitychange", callback);
      document.addEventListener("visibilitychange", callback);
      document.addEventListener("webkitvisibilitychange", callback);
      window.addEventListener("blur", callback);
      window.addEventListener("focus", callback);
      window.addEventListener("pagehide", callback);
      window.addEventListener("pageshow", callback);

      return () => {
        document.removeEventListener("blur", callback);
        document.removeEventListener("focus", callback);
        document.removeEventListener("focusin", callback);
        document.removeEventListener("focusout", callback);
        document.removeEventListener("mozvisibilitychange", callback);
        document.removeEventListener("msvisibilitychange", callback);
        document.removeEventListener("visibilitychange", callback);
        document.removeEventListener("webkitvisibilitychange", callback);
        window.removeEventListener("blur", callback);
        window.removeEventListener("focus", callback);
        window.removeEventListener("pagehide", callback);
        window.removeEventListener("pageshow", callback);
      };
    },
    () => document.visibilityState,
    (): DocumentVisibilityState => "visible"
  );
