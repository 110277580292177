import { motion, useMotionValue, useTransform } from "framer-motion";
import type { MotionValue } from "framer-motion";
import type { ComponentProps, ReactNode } from "react";

const CounterIcon = ({
  counterPosition,
  position,
  style,
  ...props
}: ComponentProps<typeof motion.div> & {
  counterPosition: MotionValue<number>;
  position: number;
}) => {
  const opacity = useTransform(
    counterPosition,
    [
      position - 3,
      position - 2,
      position - 1,
      position,
      position + 1,
      position + 2,
      position + 3,
    ],
    [0, 0.25, 0.5, 1, 0.5, 0.25, 0]
  );
  const scale = useTransform(
    counterPosition,
    [position - 1, position, position + 1],
    [0.5, 1, 0.5]
  );

  return <motion.div {...props} style={{ ...style, opacity, scale }} />;
};

export const CarouselCounter = ({
  className,
  maxValue,
  numberWidthPx,
  showingPerSide,
  style,
  value,
  children = (index) => index,
  ...props
}: Omit<ComponentProps<"div">, "children"> & {
  children?: (number: number, active: boolean) => ReactNode;
  maxValue: number;
  numberWidthPx: number;
  showingPerSide: number;
  value: number;
}) => {
  const spacerWidth = useMotionValue(0);

  const counterPosition = useTransform(
    spacerWidth,
    (value) => value / (2 * numberWidthPx)
  );

  return (
    <div
      {...props}
      className={`flex items-center justify-center overflow-x-hidden tabular-nums ${className}`}
      style={{
        ...style,
        maxWidth: (2 * showingPerSide + 1) * numberWidthPx,
      }}
    >
      <div
        className="flex-shrink-0"
        style={{
          width: (maxValue + showingPerSide + 1) * numberWidthPx,
        }}
      />
      {Array.from({ length: maxValue + showingPerSide + 2 }, (_, index) => (
        <CounterIcon
          key={index}
          className="flex flex-shrink-0 items-center justify-center"
          counterPosition={counterPosition}
          position={index}
          style={{
            width: numberWidthPx,
          }}
        >
          <div className={index === value ? "select-text" : "select-none"}>
            {children(
              index,
              value - showingPerSide - 1 <= index &&
                index <= value + showingPerSide + 1
            )}
          </div>
        </CounterIcon>
      ))}
      <motion.div
        className="flex-shrink-0"
        initial={{ width: 0 }}
        animate={{
          width: value * 2 * numberWidthPx,
        }}
        style={{ width: spacerWidth }}
      />
    </div>
  );
};
