import { minBy } from "lodash/fp";
import { useEffect, useMemo, useState } from "react";
import type { ComponentProps } from "react";

import type { Block, Position } from "@sunblocks/game";

import { remToNum } from "../../utils";
import type { BlockModel } from "../BlockModel";
import { tailwindConfig } from "../tailwind-config";
import { sumCoordinates } from "./utils";
import type { Coordinate } from "./utils";

const {
  theme: { spacing },
} = tailwindConfig;

export const usePointerController = ({
  consideringController,
  consideredBlockCoordinate,
  consideredBlockValidPositions,
  onBlockCommit,
  onBlockConsider,
  onBlockHover,
  positionToCoordinate,
  remPx,
  consideredBlock: consideredBlockRaw,
}: {
  consideredBlock: Block | undefined;
  consideredBlockCoordinate: Coordinate | undefined;
  consideredBlockValidPositions: Position[] | undefined;
  consideringController: string | undefined;
  onBlockCommit: () => void;
  onBlockConsider: (
    controller: "pointer",
    block: Block,
    position: Position
  ) => void;
  onBlockHover: (block: Block | undefined) => void;
  positionToCoordinate: (position: Position) => Coordinate;
  remPx: number;
}) => {
  const consideredBlock =
    consideringController !== "pointer" ? undefined : consideredBlockRaw;

  const [dragStart, setDragStart] =
    useState<Pick<PointerEvent, "clientX" | "clientY">>();
  const [consideredCoordinate, setConsideredCoordinate] =
    useState<Coordinate>();

  const [previousConsideredBlock, setPreviousConsideredBlock] =
    useState<Block>();
  const [previousConsideredCoordinate, setPreviousConsideredCoordinate] =
    useState<Coordinate>();

  useEffect(() => {
    if (consideredBlock && consideringController === "pointer") {
      return () => {};
    }

    const timeout = setTimeout(() => {
      setPreviousConsideredBlock(undefined);
      setPreviousConsideredCoordinate(undefined);
    });

    return () => clearTimeout(timeout);
  }, [consideredBlock, consideringController]);

  const consideredBlockValidCoordinatePositions = useMemo(
    () =>
      consideredBlock &&
      consideredBlockValidPositions?.map((position) => ({
        position,
        coordinate: positionToCoordinate(position),
      })),
    [consideredBlock, consideredBlockValidPositions, positionToCoordinate]
  );

  useEffect(() => {
    if (!dragStart) {
      return () => {};
    }

    if (
      !consideredBlock ||
      !consideredBlockCoordinate ||
      !consideredBlockValidCoordinatePositions
    ) {
      setDragStart(undefined);
      setConsideredCoordinate(undefined);
      return () => {};
    }

    setPreviousConsideredBlock(consideredBlock);

    const onPointerMove = (event: PointerEvent) => {
      const movedToCoordinate = sumCoordinates(consideredBlockCoordinate, {
        top: (event.clientY - dragStart.clientY) / remPx,
        left: (event.clientX - dragStart.clientX) / remPx,
      });

      const { coordinate: closestCoordinate, position: consideredPosition } =
        minBy(
          ({ coordinate: { top, left } }) =>
            Math.hypot(
              top - movedToCoordinate.top,
              left - movedToCoordinate.left
            ),
          consideredBlockValidCoordinatePositions
        )!;

      onBlockConsider("pointer", consideredBlock, consideredPosition);

      const ratio = Math.min(
        1,
        remToNum(spacing.betweenBlockAndCell) /
          Math.hypot(
            movedToCoordinate.top - closestCoordinate.top,
            movedToCoordinate.left - closestCoordinate.left
          )
      );

      const nextConsideredCoordinate = {
        top:
          closestCoordinate.top +
          (movedToCoordinate.top - closestCoordinate.top) * ratio,
        left:
          closestCoordinate.left +
          (movedToCoordinate.left - closestCoordinate.left) * ratio,
      };

      setConsideredCoordinate(nextConsideredCoordinate);
      setPreviousConsideredCoordinate(nextConsideredCoordinate);
    };

    const onPointerUp = onBlockCommit;

    globalThis.addEventListener("pointermove", onPointerMove);
    globalThis.addEventListener("pointerup", onPointerUp);
    return () => {
      globalThis.removeEventListener("pointermove", onPointerMove);
      globalThis.removeEventListener("pointerup", onPointerUp);
    };
  }, [
    consideredBlock,
    consideredBlockCoordinate,
    consideredBlockValidCoordinatePositions,
    dragStart,
    onBlockCommit,
    onBlockConsider,
    remPx,
  ]);

  return {
    controlledBlocks: useMemo(
      () => ({
        ...(!previousConsideredBlock || !previousConsideredCoordinate
          ? {}
          : {
              [previousConsideredBlock._key]: {
                animationClassName: undefined,
                coordinate: previousConsideredCoordinate,
              },
            }),
        ...(!consideredBlock ||
        !consideredCoordinate ||
        consideringController !== "pointer"
          ? {}
          : {
              [consideredBlock._key]: {
                animationClassName: "",
                coordinate: consideredCoordinate,
              },
            }),
      }),
      [
        consideredBlock,
        consideredCoordinate,
        consideringController,
        previousConsideredBlock,
        previousConsideredCoordinate,
      ]
    ),
    blockProps: (block: Block, { position }: { position: Position }) =>
      ({
        onPointerEnter: () => onBlockHover(block),
        onPointerLeave: () => onBlockHover(undefined),
        onPointerDown: (event) => {
          if (event.button !== 0) {
            return;
          }

          onBlockConsider("pointer", block, position);
          setDragStart(event);
          setConsideredCoordinate(positionToCoordinate(position));
          setPreviousConsideredCoordinate(positionToCoordinate(position));
        },
      } satisfies Partial<ComponentProps<typeof BlockModel>>),
  };
};
