import type { Config } from "tailwindcss";
import type { PluginUtils } from "tailwindcss/types/config";

const brown = {
  50: "#EFEBE9",
  100: "#D7CCC8",
  200: "#BCAAA4",
  300: "#A1887F",
  400: "#8D6E63",
  500: "#795548",
  600: "#6D4C41",
  700: "#5D4037",
  800: "#4E342E",
  900: "#3E2723",
};

const config = {
  theme: {
    extend: {
      colors: ({
        colors: {
          blue,
          cyan,
          gray,
          green,
          red,
          neutral,
          orange,
          pink,
          purple,
          slate,
          stone,
          teal,
          yellow,
          zinc,
        },
      }: PluginUtils) => {
        const normal = {
          inactive: stone[500],
          disactive: red[700],
          unfilled: green[500],
          sun: yellow[400],
          fire: orange[500],
          water: blue[600],
        };

        const n = {
          ...normal,
          inactive: teal[500],
        };

        const weak = {
          inactive: pink[500],
          disactive: pink[500],
          unfilled: pink[500],
          sun: pink[500],
          fire: pink[500],
          water: pink[500],
        };

        const cellInner = {
          disactive: red[500],
          sun: yellow[600],
          fire: orange[700],
          water: cyan[800],
        };

        const fired = red[600];
        const firedInner = yellow[400];

        const watered = blue[900];
        const wateredInner = cyan[400];

        const icon = {
          inactive: gray[100],
          disactive: gray[100],
          unfilled: gray[100],
          sun: gray[100],
          fire: fired,
          water: watered,
        };

        const sun = {
          ...icon,
          inactive: orange[500],
          disactive: orange[500],
          unfilled: orange[500],
          sun: orange[500],
        };

        const flower = {
          petals: { ...icon, sun: red[500] },
          leaves: { ...icon, sun: green[700] },
        };

        const fire = {
          outer: { ...icon, disactive: fired, unfilled: fired },
          inner: {
            ...icon,
            disactive: firedInner,
            unfilled: firedInner,
            fire: firedInner,
            water: wateredInner,
          },
        };

        const water = {
          ...icon,
          inactive: wateredInner,
          disactive: wateredInner,
          unfilled: wateredInner,
          water: wateredInner,
        };

        const moon = {
          ...icon,
          unfilled: yellow[200],
          stars: yellow[300],
        };

        const sprinkle = {
          inactive: orange[600],
          active: orange[400],
        };

        return {
          brown,
          fire,
          flower,
          moon,
          sprinkle,
          sun,
          water,
          block: {
            light: { n, normal, weak },
            night: {
              n,
              weak,
              normal: { ...normal, unfilled: gray[800] },
            },
            inner: {
              inert: stone[300],
              weak: {
                inactive: purple[900],
                disactive: red[600],
                unfilled: green[400],
                sun: yellow[300],
                fire: orange[400],
                water: blue[500],
              },
            },
          },
          cell: {
            light: {
              back: slate[200],
              shadow: slate[400],
              inner: { ...cellInner, unfilled: brown[700] },
            },
            dark: {
              back: zinc[600],
              shadow: zinc[400],
              inner: { ...cellInner, unfilled: brown[500] },
            },
            night: {
              back: neutral[800],
              shadow: neutral[400],
              inner: { ...cellInner, unfilled: brown[500] },
            },
            sun: {
              ...sun,
              inactive: red[900],
              disactive: red[900],
              unfilled: red[900],
              sun: red[900],
            },
            flower: {
              petals: {
                ...flower.petals,
                inactive: red[700],
                disactive: red[700],
                unfilled: red[700],
                sun: red[600],
              },
              leaves: {
                ...flower.leaves,
                inactive: red[700],
                disactive: red[700],
                unfilled: red[700],
                sun: green[800],
              },
            },
          },
        };
      },
      fontSize: {
        control: "3rem" as const,
      },
      spacing: {
        cell: "8rem" as const,
        betweenBlockAndCell: "7.5rem" as const,
        distanceBetween: "1rem",
        block: "7rem" as const,
        blockContent: "6rem" as const,
        blockInnerContent: "5rem" as const,
        blockInnerInnerContent: "3.5rem" as const,
        menu: "4rem" as const,
        control: "3rem" as const,
      },
    },
  },
  plugins: [],
} satisfies Omit<Config, "content">;

export default config;
