import DarkModeRounded from "@mui/icons-material/DarkModeRounded";
import StarRounded from "@mui/icons-material/StarRounded";
import { motion } from "framer-motion";
import type { ComponentProps } from "react";

import { remToNum } from "../../utils";
import { MotionDiv } from "../Motion";
import { Sprinkle } from "../Sprinkle";
import { tailwindConfig } from "../tailwind-config";

const {
  theme: {
    spacing,
    colors: { moon },
  },
} = tailwindConfig;

export const Moon = ({
  className,
  immediate = false,
  ...props
}: ComponentProps<typeof MotionDiv> & {
  immediate?: boolean;
}) => (
  <motion.div
    className={`pointer-events-none relative size-blockContent ${className}`}
  >
    {!immediate && (
      <Sprinkle className="absolute top-0" variantName="unfilled" />
    )}
    <MotionDiv
      {...props}
      className="absolute top-0"
      variants={{
        inactive: { opacity: 0.5, scale: 1, color: moon.inactive },
        disactive: { opacity: 0.75, scale: 1, color: moon.disactive },
        sun: { opacity: 0.75, scale: 1, color: moon.unfilled },
        fire: { opacity: 1, scale: 1, color: moon.fire },
        water: { opacity: 1, scale: 1, color: moon.water },
        unfilled: {
          opacity: 1,
          scale: [1, 2, 1.8, 1],
          color: moon.unfilled,
          transition: immediate
            ? { delay: 0, duration: 0 }
            : { delay: 0.15, duration: 0.4, bounce: true },
        },
      }}
    >
      <DarkModeRounded
        style={{
          height: spacing.blockContent,
          width: spacing.blockContent,
        }}
      />
    </MotionDiv>
    <motion.div
      variants={{
        start: { opacity: 0 },
        inactive: { opacity: 0 },
        disactive: { opacity: 0 },
        sun: { opacity: 0 },
        fire: { opacity: 0 },
        water: { opacity: 0 },
        animating: {
          opacity: 1,
          transition: { delay: 0.75 },
        },
      }}
    >
      <motion.div
        className="absolute right-5 top-0 text-moon-stars"
        variants={{
          start: { opacity: 0, scale: 1, rotate: "-36deg" },
          inactive: { opacity: 0, scale: 1 },
          disactive: { opacity: 0, scale: 1 },
          sun: { opacity: 0, scale: 1 },
          fire: { opacity: 0, scale: 1 },
          water: { opacity: 0, scale: 1 },
          animating: {
            scale: [1, 1.3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            opacity: [1, 0.5],
            transition: {
              delay: 1.75,
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            },
          },
        }}
      >
        <StarRounded
          className="-translate-y-1/4"
          style={{
            height: `${remToNum(spacing.blockContent) / 7}rem`,
            width: `${remToNum(spacing.blockContent) / 7}rem`,
          }}
        />
      </motion.div>
      <motion.div
        className="absolute right-8 top-5 text-moon-stars"
        variants={{
          start: { opacity: 0, scale: 1, rotate: "20deg" },
          inactive: { opacity: 0, scale: 1 },
          disactive: { opacity: 0, scale: 1 },
          sun: { opacity: 0, scale: 1 },
          fire: { opacity: 0, scale: 1 },
          water: { opacity: 0, scale: 1 },
          animating: {
            scale: [1, 1.3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            opacity: [1, 0.5],
            transition: {
              delay: 2.25,
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            },
          },
        }}
      >
        <StarRounded
          className="-translate-y-2/3"
          style={{
            height: `${remToNum(spacing.blockContent) / 8}rem`,
            width: `${remToNum(spacing.blockContent) / 8}rem`,
          }}
        />
      </motion.div>
      <motion.div
        className="absolute right-2 top-6 text-moon-stars"
        variants={{
          start: { opacity: 0, scale: 1, rotate: "-12deg" },
          inactive: { opacity: 0, scale: 1 },
          disactive: { opacity: 0, scale: 1 },
          sun: { opacity: 0, scale: 1 },
          fire: { opacity: 0, scale: 1 },
          water: { opacity: 0, scale: 1 },
          animating: {
            scale: [1, 1.3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            opacity: [1, 0.5],
            transition: {
              delay: 2.75,
              duration: 5,
              repeat: Infinity,
              ease: "linear",
            },
          },
        }}
      >
        <StarRounded
          className="-translate-y-1/3"
          style={{
            height: `${remToNum(spacing.blockContent) / 6}rem`,
            width: `${remToNum(spacing.blockContent) / 6}rem`,
          }}
        />
      </motion.div>
    </motion.div>
  </motion.div>
);
