import {
  createContext,
  useContext,
  useMemo,
  useSyncExternalStore,
} from "react";
import type { ReactNode } from "react";

const MatchMediaContext = createContext({} as { [query: string]: boolean });

export const useMatchMedia = (mediaQuery: string) => {
  const context = useContext(MatchMediaContext);
  const { [mediaQuery]: override } = context;

  const matches = useSyncExternalStore(
    (callback) => {
      const mediaQueryList = globalThis.matchMedia(mediaQuery);

      mediaQueryList.addEventListener("change", callback);
      return () => mediaQueryList.removeEventListener("change", callback);
    },
    () => globalThis.matchMedia(mediaQuery).matches,
    () => undefined
  );

  return override ?? matches;
};

// eslint-disable-next-line import/no-unused-modules -- TODO Used in storybook preview.tsx https://github.com/storybookjs/storybook/issues/29144
export const OverrideMatchMedia = ({
  children,
  matches,
  mediaQuery,
}: {
  children: ReactNode;
  matches?: boolean;
  mediaQuery: string;
}) => {
  const prevQueries = useContext(MatchMediaContext);
  const queries = useMemo(
    () =>
      matches === undefined
        ? prevQueries
        : { ...prevQueries, [mediaQuery]: matches },
    [matches, mediaQuery, prevQueries]
  );

  return (
    <MatchMediaContext.Provider value={queries}>
      {children}
    </MatchMediaContext.Provider>
  );
};
